/**
 * SimpleLoader.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from 'react';
import './SimpleLoader.css';

/**
 * Simple Loader component
 * This Loader takes NO! Props.
 */
const SimpleLoader = (props) => (
    <div className="simple-spinner" data-test="simple-spinner">
        <div className="simple-spinner-spinner"></div>
    </div>
);


export default SimpleLoader;