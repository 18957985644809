import React from 'react';
import Input from './../../../components/Input/Input';
import SimpleLoader from './../../../components/Loader/SimpleLoader';
import './../../../components/Modal/Modal.css';

export const CONTACT_MODAL_TYPE = {
    CONTACT:       "CONTACT",
};

export const CONTACT_MODAL_ACTION = {
    CREATE:         "CREATE",
    UPDATE:         "UPDATE",
    DELETE:         "DELETE"
};

export const CONTACT_MODAL_HEADLINE = {
    CONTACT:        "Kontakt",
    CREATE:         "erstellen",
    UPDATE:         "bearbeiten",
    DELETE:         "löschen",
}

class ModalContacts extends React.Component {
    state = {
        [CONTACT_MODAL_TYPE.CONTACT]: {
            [CONTACT_MODAL_ACTION.CREATE]: {
                application_title_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Anrede',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: 0, defaultOptionText: 'Bitte wählen', defaultOptionDisabled: true},  
                    value: '',  
                    touched: true, 
                    css: 'modal_content' 
                },
                customer_contact_given_name: {
                    elementType: 'inputWithLabel',
                    label: 'Vorname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_sur_name: {
                    elementType: 'inputWithLabel',
                    label: 'Nachname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_department: {
                    elementType: 'inputWithLabel',
                    label: 'Abteilung',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_position: {
                    elementType: 'inputWithLabel',
                    label: 'Position',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_landline: {
                    elementType: 'inputWithLabel',
                    label: 'Festnetz',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_cellphone_work: {
                    elementType: 'inputWithLabel',
                    label: 'Mobiltelefon',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Standort',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: "0", defaultOptionText: 'Kein Standort', defaultOptionDisabled: false},  
                    value: '',  
                    touched: true, 
                    css: 'modal_content' 
                }
                // customer_contact_additional_1: {
                //     elementType: 'inputWithLabel',
                //     label: 'Zusatz 1',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_contact_additional_2: {
                //     elementType: 'inputWithLabel',
                //     label: 'Zusatz 2',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // }
            },
            [CONTACT_MODAL_ACTION.UPDATE]: {
                customer_contact_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }, 
                application_title_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Anrede',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: 0, defaultOptionText: 'Bitte wählen', defaultOptionDisabled: true},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content' 
                },
                customer_contact_given_name: {
                    elementType: 'inputWithLabel',
                    label: 'Vorname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_sur_name: {
                    elementType: 'inputWithLabel',
                    label: 'Nachname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_department: {
                    elementType: 'inputWithLabel',
                    label: 'Abteilung',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_position: {
                    elementType: 'inputWithLabel',
                    label: 'Position',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_landline: {
                    elementType: 'inputWithLabel',
                    label: 'Festnetz',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_cellphone_work: {
                    elementType: 'inputWithLabel',
                    label: 'Mobiltelefon',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_contact_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Standort',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: "0", defaultOptionText: 'Kein Standort', defaultOptionDisabled: false},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content' 
                },
                // customer_contact_additional_1: {
                //     elementType: 'inputWithLabel',
                //     label: 'Zusatz 1',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_contact_additional_2: {
                //     elementType: 'inputWithLabel',
                //     label: 'Zusatz 2',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // }
            },
        }
    }



    changeHandler = (event, targetId) => {
        let update = {
            ...this.state[this.props.type][this.props.action],
            [targetId]: {
                ...this.state[this.props.type][this.props.action][targetId],
                value: event.target.value,
                touched: true
            }
        }
        this.setState((state) =>({
            [this.props.type]: {
                ...state[this.props.type],
                [this.props.action]: update
            }
        })); 
    }

    mapValuesToState = () => {

        if(this.props.action !== null && this.props.type !== null) {
            let updateValues = {
                ...this.state[this.props.type][this.props.action]
            };
            if (this.props.type === CONTACT_MODAL_TYPE.CONTACT) {
                switch(this.props.action){
                    case CONTACT_MODAL_ACTION.CREATE:
                         updateValues = {
                                 ...updateValues,
                                application_title_id: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].application_title_id,
                                    value: '1', 
                                    elementConfig: {
                                        ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].application_title_id.elementConfig, 
                                        options: this.props.applicationTitleOptions || []
                                    }
                                },
                                customer_contact_given_name: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_given_name,
                                    value: '',
                                },
                                customer_contact_sur_name: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_sur_name,
                                    value: '',
                                },
                                customer_contact_department: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_department,
                                    value: '',
                                },
                                customer_contact_position: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_position,
                                    value: '',
                                },
                                customer_contact_landline: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_landline,
                                    value: '',
                                },
                                customer_contact_cellphone_work: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_cellphone_work,
                                    value: '',
                                },
                                customer_contact_mail: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_contact_mail,
                                    value: '',
                                },
                                customer_branch_id: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_branch_id,
                                     // value must be props or "0" as a string!!!
                                     // Rest API recodgnizes the "0" and stores a null value into the database!
                                    value: "0",
                                    elementConfig: {
                                        ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.CREATE].customer_branch_id.elementConfig,
                                        options: this.props.selectBranchOptions || []
                                    }
                                },
                             }
                             break;
                         case CONTACT_MODAL_ACTION.UPDATE:
                             updateValues = {
                                 ...updateValues,
                                customer_contact_id: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_id,
                                    value: (this.props.content.customer_contact_id || '1'),
                                },
                                application_title_id: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].application_title_id,
                                    value: (this.props.content.application_title_id || ''), 
                                    elementConfig: {
                                        ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].application_title_id.elementConfig, 
                                        options: this.props.applicationTitleOptions || []
                                    }
                                },
                                customer_contact_given_name: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_given_name,
                                    value: (this.props.content.customer_contact_given_name || ''),
                                },
                                customer_contact_sur_name: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_sur_name,
                                    value: (this.props.content.customer_contact_sur_name || ''),
                                },
                                customer_contact_department: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_department,
                                    value: (this.props.content.customer_contact_department || ''),
                                },
                                customer_contact_position: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_position,
                                    value: (this.props.content.customer_contact_position || ''),
                                },
                                customer_contact_landline: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_landline,
                                    value: (this.props.content.customer_contact_landline || ''),
                                },
                                customer_contact_cellphone_work: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_cellphone_work,
                                    value: (this.props.content.customer_contact_cellphone_work || ''),
                                },
                                customer_contact_mail: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_contact_mail,
                                    value: (this.props.content.customer_contact_mail || ''),
                                },
                                customer_branch_id: {
                                    ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_branch_id,
                                    // value must be props or "0" as a string!!!
                                    // Rest API recodgnizes the "0" and stores a null value into the database!
                                    value: (this.props.content.customer_branch_id || "0"),
                                    elementConfig: {
                                        ...this.state[CONTACT_MODAL_TYPE.CONTACT][CONTACT_MODAL_ACTION.UPDATE].customer_branch_id.elementConfig,
                                        options: this.props.selectBranchOptions || []
                                    }
                                }
                             }
                             break;
                        default: 
                        return null;
                    }
            } else this.props.close();
            this.setState((state) =>({
                [this.props.type]: {
                    ...state[this.props.type],
                    [this.props.action]: updateValues
                }
            }));
        }
    }

    buildSubmitObject = () => {
        let obj = {};
        for(let key in this.state[this.props.type][this.props.action]) {
            if(!this.state[this.props.type][this.props.action][key].touched) continue;
            else obj = { ...obj, [key]: this.state[this.props.type][this.props.action][key].value };
        }
        return obj;
    }

    
    
    submitHandler = () => {
        const values = this.buildSubmitObject();
        if(Object.keys(values).length >= 3 && this.props.action === CONTACT_MODAL_ACTION.CREATE) {
            this.props.modalHandler(this.props.type, this.props.action, values, this.props.close)
        } else if (Object.keys(values).length >= 1 && this.props.action === CONTACT_MODAL_ACTION.UPDATE) {
            this.props.modalHandler(this.props.type, this.props.action, values, this.props.close)
        }
    }
    
    deleteHandler = () => {
        let delMsg= `Möchten Sie den ausgewählten Kunden endgültig löschen? \nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;


        let delDialog = window.confirm(delMsg);
        const values = this.buildSubmitObject();
        if (delDialog) this.props.modalHandler(this.props.type, CONTACT_MODAL_ACTION.DELETE, values, this.props.close);
    }
    

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.action !== this.props.action ||
        prevProps.type !== this.props.type || 
        prevProps.content !== this.props.content ) this.mapValuesToState();

    }


    render() {
        let formElements = [];
        let formContent = null;
        let formHeadline = "";
        let modal = null;

        if(this.props.action !== null && this.props.type !== null) {
            // No Functional Code above this If-Check!
            for(let key in this.state[this.props.type][this.props.action]) {
                formElements.push({
                    id: key,
                    config:  this.state[this.props.type][this.props.action][key]
                })
            }
            if(this.props.loading) formContent = <SimpleLoader />;
            else formContent = formElements.map(el => (
                <Input 
                    key={el.id}
                    value={el.config.value}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    css={el.config.css}
                    label={el.config.label}
                    changed={(event) => this.changeHandler(event, el.id)}
                    disabled={this.props.inProcessing}
                />
            ))

            formHeadline = CONTACT_MODAL_HEADLINE[this.props.type] + " " + CONTACT_MODAL_HEADLINE[this.props.action];
        
        } 
        // No Functional Code below this If-Check!

        if(this.props.show) 
            modal = (
                <div className="modal_parent" >
                <div className="modal">
                    <div className="modal_area">
                        <div className="modal_headline">
                            {formHeadline}
                        </div>
                            {formContent}
                            <div className="modal_functions">
                                {this.props.action === CONTACT_MODAL_ACTION.UPDATE ? 
                                (
                                <button className="basic_btn_icon" type="" value="Löschen" onClick={() => this.deleteHandler()}>
                                    <i className="material-icons">
                                        delete
                                    </i>
                                </button>
                                ) 
                                : null}
                                <button className="basic_btn_icon" type="reset"  value="Abbrechen" onClick={() => this.props.close()}>
                                    <i className="material-icons">
                                        close
                                    </i>
                                </button>
                                <button className="basic_btn_icon" type="submit" value="Speichern" onClick={() => this.submitHandler()}>
                                    <i className="material-icons">
                                        save
                                    </i>
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            );
    
        return modal;
    }
}

export default ModalContacts;