/**
 * ModalReviewGroups.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from 'react';
import Input from './../../../components/Input/Input';
import SimpleLoader from './../../../components/Loader/SimpleLoader';
import './../../../components/Modal/Modal.css';

export const GROUPS_MODAL_TYPE = {
    TEST_GROUP:     "TEST_GROUP",
    TEST_QUESTION:  "TEST_QUESTION",
    TEST_FAULT:     "TEST_FAULT",
    TEST_ACTION:    "TEST_ACTION",
};

export const GROUPS_MODAL_ACTION = {
    CREATE:         "CREATE",
    UPDATE:         "UPDATE",
    DELETE:         "DELETE"
};

export const GROUPS_MODAL_HEADLINE = {
    TEST_GROUP:     "Prüfgruppe",
    TEST_QUESTION:  "Prüffrage",
    TEST_FAULT:     "Fehler",
    TEST_ACTION:    "Maßnahme",
    CREATE:         "erstellen",
    UPDATE:         "bearbeiten",
    DELETE:         "löschen",
}

class ModalReviewGroups extends React.Component {
    state = {
        /** -----------------------------------------------------------------------------
         * TEST_GROUP
         */
        [GROUPS_MODAL_TYPE.TEST_GROUP]: {
            [GROUPS_MODAL_ACTION.CREATE]: {
                // test_group_name
                test_group_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 150},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
            [GROUPS_MODAL_ACTION.UPDATE]: {
                test_group_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: true, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                },
                test_group_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 150},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
        },
        /** -----------------------------------------------------------------------------
         * TEST_QUESTION
         */
        [GROUPS_MODAL_TYPE.TEST_QUESTION]: {
            [GROUPS_MODAL_ACTION.CREATE]: {
                test_question_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
            [GROUPS_MODAL_ACTION.UPDATE]: {
                test_question_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }, 
                test_question_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content small'
                }
            },
        },
        /** -----------------------------------------------------------------------------
         * TEST_FAULT
         */
        [GROUPS_MODAL_TYPE.TEST_FAULT]: {
            [GROUPS_MODAL_ACTION.CREATE]: {
                test_fault_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content small'
                }, 
                test_fault_description: {
                     elementType: 'textareaWithLabel',
                    label: 'Beschreibung', 
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: ''}, 
                    value: '',  
                    touched: false, 
                    css: 'modal_content medium' 
                }
            },
            [GROUPS_MODAL_ACTION.UPDATE]: {
                test_fault_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }, 
                test_fault_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content small'
                }, 
                test_fault_description: {
                     elementType: 'textareaWithLabel',
                    label: 'Beschreibung', 
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: ''}, 
                    value: '',  
                    touched: false, 
                    css: 'modal_content medium' 
                }
            },
        },
        /** -----------------------------------------------------------------------------
         * TEST_FAULT_ACTION
         */
        [GROUPS_MODAL_TYPE.TEST_ACTION]: {
            [GROUPS_MODAL_ACTION.CREATE]: {
                test_fault_action_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content small'
                }, 
                test_fault_action_template: {
                    elementType: 'textareaWithLabel',
                    label: 'Vorlage', 
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 1000}, 
                    value: '',  
                    touched: false, 
                    css: 'modal_content medium' 
                }
            },
            [GROUPS_MODAL_ACTION.UPDATE]: {
                test_fault_action_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }, 
                test_fault_action_name: {
                    elementType: 'textareaWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content small'
                }, 
                test_fault_action_template: {
                    elementType: 'textareaWithLabel',
                    label: 'Vorlage', 
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 1000}, 
                    value: '',  
                    touched: false, 
                    css: 'modal_content medium' 
                }
            },
            [GROUPS_MODAL_ACTION.DELETE]: {
                test_fault_action_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }
            },
        },
    }




    changeHandler = (event, targetId) => {
        let update = {
            ...this.state[this.props.type][this.props.action],
            [targetId]: {
                ...this.state[this.props.type][this.props.action][targetId],
                value: event.target.value,
                touched: true
            }
        }
        this.setState((state) =>({
            [this.props.type]: {
                ...state[this.props.type],
                [this.props.action]: update
            }
        })); 
    }

    mapValuesToState = () => {
        if(this.props.action !== null && this.props.type !== null) {
            let updateValues = {
                ...this.state[this.props.type][this.props.action]
            };
            if(this.props.type === GROUPS_MODAL_TYPE.TEST_GROUP) {
                switch(this.props.action){
                        case GROUPS_MODAL_ACTION.CREATE:
                            updateValues = {    
                                ...updateValues,
                                test_group_name: {  
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_GROUP][GROUPS_MODAL_ACTION.CREATE].test_group_name, 
                                    value: ''
                                }
                            }
                            break;
                        case GROUPS_MODAL_ACTION.UPDATE:
                            updateValues = {
                                ...updateValues,
                                test_group_id: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_GROUP][GROUPS_MODAL_ACTION.UPDATE].test_group_id,
                                    value: (this.props.content.test_group_id || undefined)
                                },
                                test_group_name: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_GROUP][GROUPS_MODAL_ACTION.UPDATE].test_group_name,
                                    value: (this.props.content.test_group_name || undefined)
                                }
                            }
                            break;
                        default: return null;
                    }
            } else if (this.props.type === GROUPS_MODAL_TYPE.TEST_QUESTION) {
                    switch (this.props.action) {
                        case GROUPS_MODAL_ACTION.CREATE:
                            updateValues = {
                                ...updateValues,
                                test_question_name: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_QUESTION][GROUPS_MODAL_ACTION.CREATE].test_question_name,
                                    value: ''
                                }
                            }
                            break;
                        case GROUPS_MODAL_ACTION.UPDATE:
                            updateValues = {
                                ...updateValues,
                                test_question_id: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_QUESTION][GROUPS_MODAL_ACTION.UPDATE].test_question_id,
                                    value: (this.props.content.test_question_id || undefined)
                                },
                                test_question_name: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_QUESTION][GROUPS_MODAL_ACTION.UPDATE].test_question_name,
                                    value: (this.props.content.test_question_name || undefined)
                                }
                            }
                            break;
                        default:
                            return null;
                    }
            } else if( this.props.type === GROUPS_MODAL_TYPE.TEST_FAULT) {
                    switch (this.props.action) {
                        case GROUPS_MODAL_ACTION.CREATE:
                            updateValues = {
                                ...updateValues,
                                test_fault_name: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_FAULT][GROUPS_MODAL_ACTION.CREATE].test_fault_name,
                                    value: ''
                                },
                                test_fault_description: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_FAULT][GROUPS_MODAL_ACTION.CREATE].test_fault_description,
                                    value: ''
                                }
                            }
                            break;
                        case GROUPS_MODAL_ACTION.UPDATE:
                        updateValues = {
                                ...updateValues,
                                test_fault_id: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_FAULT][GROUPS_MODAL_ACTION.UPDATE].test_fault_id,
                                    value: (this.props.content.test_fault_id || undefined)
                                },
                                test_fault_name: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_FAULT][GROUPS_MODAL_ACTION.UPDATE].test_fault_name,
                                    value: (this.props.content.test_fault_name || undefined)
                                },
                                test_fault_description: {
                                    ...this.state[GROUPS_MODAL_TYPE.TEST_FAULT][GROUPS_MODAL_ACTION.UPDATE].test_fault_description,
                                    value: (this.props.content.test_fault_description || undefined)
                                }
                            }
                            break;
                        default:
                            return null;
                    }
            } else if( this.props.type === GROUPS_MODAL_TYPE.TEST_ACTION) {
                switch (this.props.action) {
                    case GROUPS_MODAL_ACTION.CREATE:
                        updateValues = {
                            ...updateValues,
                            test_fault_action_name: {
                                ...this.state[GROUPS_MODAL_TYPE.TEST_ACTION][GROUPS_MODAL_ACTION.CREATE].test_fault_action_name,
                                value: ''
                            },
                            test_fault_action_template: {
                                ...this.state[GROUPS_MODAL_TYPE.TEST_ACTION][GROUPS_MODAL_ACTION.CREATE].test_fault_action_template,
                                value: ''
                            }
                        }
                    break;
                    case GROUPS_MODAL_ACTION.UPDATE:
                        updateValues = {
                            ...updateValues,
                            test_fault_action_id: {
                                ...this.state[GROUPS_MODAL_TYPE.TEST_ACTION][GROUPS_MODAL_ACTION.UPDATE].test_fault_action_id,
                                value: (this.props.content.test_fault_action_id || undefined)
                            },
                            test_fault_action_name: {
                                ...this.state[GROUPS_MODAL_TYPE.TEST_ACTION][GROUPS_MODAL_ACTION.UPDATE].test_fault_action_name,
                                value: (this.props.content.test_fault_action_name || undefined)
                            },
                            test_fault_action_template: {
                                ...this.state[GROUPS_MODAL_TYPE.TEST_ACTION][GROUPS_MODAL_ACTION.UPDATE].test_fault_action_template,
                                value: (this.props.content.test_fault_action_template || undefined)
                            }
                        }
                    break;
                    default:
                    return null;
                }
            } else this.props.close();
            this.setState((state) =>({
                [this.props.type]: {
                    ...state[this.props.type],
                    [this.props.action]: updateValues
                }
            }));
        }
    } 

    buildSubmitObject = () => {
        let obj = {};
        for(let key in this.state[this.props.type][this.props.action]) {
            if(!this.state[this.props.type][this.props.action][key].touched) continue;
            else obj = { ...obj, [key]: this.state[this.props.type][this.props.action][key].value };
        }
        return obj;
    }

    
    
    submitHandler = () => {
        const values = this.buildSubmitObject();
        if(Object.keys(values).length >= 1) {
            this.props.modalHandler(this.props.type, this.props.action, values, this.props.close);
        }
    }
    
    deleteHandler = () => {
        let delMsg;
        switch(this.props.type){
            case GROUPS_MODAL_TYPE.TEST_GROUP:
                delMsg = `Möchten Sie die ausgewählte Prüfgruppe vollständig entfernen?\nAlle verknüften Prüffragen, Fehler und Maßnahmen werden ebenfalls entfernt!\nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;
                break;
            case GROUPS_MODAL_TYPE.TEST_QUESTION:
                delMsg = `Möchten Sie die ausgewählte Prüffrage wirklich löschen? \nAlle verknüften Fehler und Maßnahmen werden dadurch ebenfalls entfernt.\nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;
                break;
            case GROUPS_MODAL_TYPE.TEST_FAULT:
                delMsg = `Möchten Sie den ausgewählten Fehler wirklich löschen? \nAlle verknüpften Maßnahmen werden dadurch ebenfalls entfernt.\nAchtung, diese Aktion kann nicht Rückgängig gemacht werden!\n`;
                break;
            case GROUPS_MODAL_TYPE.TEST_ACTION:
                delMsg = `Möchten Sie die ausgewählte Maßnahme wirklich löschen? \nAchtung, diese Aktion kann nicht Rückgängig gemacht werden!\n`;
                break;
            default: 
                delMsg = `Möchten Sie Ihre Auswahl endgültig löschen? \nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;
                break;
        }
        let delDialog = window.confirm(delMsg);
        const values = this.buildSubmitObject();
        if (delDialog) this.props.modalHandler(this.props.type, GROUPS_MODAL_ACTION.DELETE, values, this.props.close);
    }
    

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.action !== this.props.action ||
        prevProps.type !== this.props.type || 
        prevProps.content !== this.props.content ) this.mapValuesToState();

    }


    render() {
        let formElements = [];
        let formContent = null;
        let formHeadline = "";
        let modal = null;

        if(this.props.action !== null && this.props.type !== null) {
            // No Functional Code above this If-Check!
            for(let key in this.state[this.props.type][this.props.action]) {
                formElements.push({
                    id: key,
                    config:  this.state[this.props.type][this.props.action][key]
                })
            }
            if(this.props.loading) formContent = <SimpleLoader />;
            else formContent = formElements.map(el => (
                <Input 
                    key={el.id}
                    value={el.config.value}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    css={el.config.css}
                    label={el.config.label}
                    changed={(event) => this.changeHandler(event, el.id)}
                    disabled={this.props.inProcessing}
                />
            ))

            formHeadline = GROUPS_MODAL_HEADLINE[this.props.type] + " " + GROUPS_MODAL_HEADLINE[this.props.action];
        
        } 
        // No Functional Code below this If-Check!

        if(this.props.show) 
            modal = (
                <div className="modal_parent" >
                <div className="modal">
                    <div className="modal_area">
                        <div className="modal_headline">
                            {formHeadline}
                        </div>
                            {formContent}
                            <div className="modal_functions">
                                {this.props.action === GROUPS_MODAL_ACTION.UPDATE ? 
                                (
                                <button className="basic_btn_icon" type="" value="Löschen" onClick={() => this.deleteHandler()}>
                                    <i className="material-icons">
                                        delete
                                    </i>
                                </button>
                                ) 
                                : null}
                                <button className="basic_btn_icon" type="reset"  value="Abbrechen" onClick={() => this.props.close()}>
                                    <i className="material-icons">
                                        close
                                    </i>
                                </button>
                                <button className="basic_btn_icon" type="submit" value="Speichern" onClick={() => this.submitHandler()}>
                                    <i className="material-icons">
                                        save
                                    </i>
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            );
    
        return modal;
    }
}

export default ModalReviewGroups;