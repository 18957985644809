//
// helper.js
// kbsoft-webapp
// 
// Created by Tim Flauger on 20.09.2019
// Copyright ©  digiply GmbH. All rights reserved.
// 
//
const jwtDecode = require('jwt-decode');

export const _decodeToken = (token) => {
    try {
        let decode = jwtDecode(token);
        if (decode) return true
        else return false
    } catch (error) {
        return false
    }
}


/**-----------------------------------------------------------
 * Update Object Function
 * @param {Object} oldObject
 * @param {Object} updatedProperties
 * @returns {Object} updatedObject
 * @author Tim.Flauger
 */
export const _updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

/**-----------------------------------------------------------
 * Edit Child inside Object by key
 * @param {Object} object     Object
 * @param {Number} key       key of Object or Value inside Object
 * @param {Object} element  New Element
 * @returns {Object} Copy of Object with the edited object
 * @author Tim.Flauger
 */
export const _editObjectChildByKey = (object, key, element) => {
    let update = {
        ...object
    };
    update[key] = element;
    return update;
};

/**-----------------------------------------------------------
 * Remove Objectchild by Key
 * @param {Object} object 
 * @param {String} key 
 * @returns {Object}
 * @author Tim.Flauger
 */
export const _removeObjectChildByKey = (object, key) => {
    const { [key]: deleted, ...newObject } = object;
    return newObject; 
};
