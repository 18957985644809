/**
 * notification.reducer.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 28.01.2020
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import * as actionTypes from './../actionTypes';

const initialState = {};

const notificationAdd = (state, action) => {
    let id = new Date().getTime();
    return {
        ...state,
        [id]: {
            type: action.notificationType,
            text: action.text,
        }
    }
}
const notificationDismiss = (state, action) => {
    let notify = {...state}
    delete notify[action.id]
    return {...notify};
}

const notificationClearAll = (state, action) => {
    return initialState;
}


const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.APP_NOTIFY_ADD: return notificationAdd(state, action);
        case actionTypes.APP_NOTIFY_DISMISS: return notificationDismiss(state, action);
        case actionTypes.APP_NOTIFY_CLEARALL: return notificationClearAll(state, action);
        default:
        return state;
    }
};

export default notificationReducer;
