/**
 * Logout.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./../../store/index";

class Logout extends React.Component {
    componentDidMount () {
        this.props.logout();
    }
    render() {
        return <Redirect to="/" />;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.handleLogout())
    };
};

export default connect( null, mapDispatchToProps )( Logout );
