import React from 'react';
import Input from './../../../components/Input/Input';
import SimpleLoader from './../../../components/Loader/SimpleLoader';
import './../../../components/Modal/Modal.css';

export const CUSTOMER_MODAL_TYPE = {
    CUSTOMER:       "CUSTOMER",
    BRANCH:         "BRANCH",
};

export const CUSTOMER_MODAL_ACTION = {
    CREATE:         "CREATE",
    UPDATE:         "UPDATE",
    DELETE:         "DELETE"
};

export const CUSTOMER_MODAL_HEADLINE = {
    CUSTOMER:       "Kunden",
    BRANCH:         "Standort",
    CREATE:         "erstellen",
    UPDATE:         "bearbeiten",
    DELETE:         "löschen",
}

class ModalCustomer extends React.Component {
    state = {
        /** -----------------------------------------------------------------------------
         * CUSTOMER
         */
        [CUSTOMER_MODAL_TYPE.CUSTOMER]: {
            [CUSTOMER_MODAL_ACTION.CREATE]: {
                customer_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 150},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
            [CUSTOMER_MODAL_ACTION.UPDATE]: {
                customer_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: true, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                },
                customer_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 150},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
        },
        /** -----------------------------------------------------------------------------
         * BRANCH
         */
        [CUSTOMER_MODAL_TYPE.BRANCH]: {
            [CUSTOMER_MODAL_ACTION.CREATE]: {
                customer_branch_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_address: {
                    elementType: 'inputWithLabel',
                    label: 'Adresse',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_address_additional: {
                    elementType: 'inputWithLabel',
                    label: 'Adresszusatz',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_zip: {
                    elementType: 'inputWithLabel',
                    label: 'Postleitzahl',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 9},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_city: {
                    elementType: 'inputWithLabel',
                    label: 'Stadt',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                // customer_branch_state: {
                //     elementType: 'inputWithLabel',
                //     label: 'Bundesland',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                customer_branch_country: {
                    elementType: 'inputWithLabel',
                    label: 'Land',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 29},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_phone: {
                    elementType: 'inputWithLabel',
                    label: 'Telefon',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 29},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                // customer_branch_web: {
                //     elementType: 'inputWithLabel',
                //     label: 'URL',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_branch_tax_number: {
                //     elementType: 'inputWithLabel',
                //     label: 'Steuernummer',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 49},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_branch_vat_number: {
                //     elementType: 'inputWithLabel',
                //     label: 'USt-Id.-Nummer',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 49},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // }
            },
            [CUSTOMER_MODAL_ACTION.UPDATE]: {
                customer_branch_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                }, 
                customer_branch_name: {
                    elementType: 'inputWithLabel',
                    label: 'Name',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_address: {
                    elementType: 'inputWithLabel',
                    label: 'Adresse',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 100},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_address_additional: {
                    elementType: 'inputWithLabel',
                    label: 'Adresszusatz',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_zip: {
                    elementType: 'inputWithLabel',
                    label: 'Postleitzahl',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 9},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_city: {
                    elementType: 'inputWithLabel',
                    label: 'Stadt',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                // customer_branch_state: {
                //     elementType: 'inputWithLabel',
                //     label: 'Bundesland',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 44},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                customer_branch_country: {
                    elementType: 'inputWithLabel',
                    label: 'Land',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 29},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_phone: {
                    elementType: 'inputWithLabel',
                    label: 'Telefon',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', maxLength: 29},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                customer_branch_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                // customer_branch_web: {
                //     elementType: 'inputWithLabel',
                //     label: 'URL',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 250},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_branch_tax_number: {
                //     elementType: 'inputWithLabel',
                //     label: 'Steuernummer',          
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 49},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // },
                // customer_branch_vat_number: {
                //     elementType: 'inputWithLabel',
                //     label: 'USt-Id.-Nummer',
                //     validate: { required: false, regex: '', valid: false, message: '' }, 
                //     elementConfig: { type: 'text', placeholder: '', maxLength: 49},  
                //     value: '',  
                //     touched: false, 
                //     css: 'modal_content'
                // }
            },
        },
    }
    changeHandler = (event, targetId) => {
        let update = {
            ...this.state[this.props.type][this.props.action],
            [targetId]: {
                ...this.state[this.props.type][this.props.action][targetId],
                value: event.target.value,
                touched: true
            }
        }
        this.setState((state) =>({
            [this.props.type]: {
                ...state[this.props.type],
                [this.props.action]: update
            }
        })); 
    }

    mapValuesToState = () => {

        if(this.props.action !== null && this.props.type !== null) {
            let updateValues = {
                ...this.state[this.props.type][this.props.action]
            };
            if(this.props.type === CUSTOMER_MODAL_TYPE.CUSTOMER) {
                switch(this.props.action){
                        case CUSTOMER_MODAL_ACTION.CREATE:
                            updateValues = {
                                ...updateValues,
                                customer_name: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.CUSTOMER][CUSTOMER_MODAL_ACTION.CREATE].customer_name,
                                    value: ''
                                }
                            }
                            break;
                        case CUSTOMER_MODAL_ACTION.UPDATE:
                            updateValues = {
                                ...updateValues,
                                customer_id: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.CUSTOMER][CUSTOMER_MODAL_ACTION.UPDATE].customer_id,
                                    value: (this.props.content.customer_id || undefined)
                                },
                                customer_name: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.CUSTOMER][CUSTOMER_MODAL_ACTION.UPDATE].customer_name,
                                    value: (this.props.content.customer_name || undefined)
                                }
                            }
                            break;
                        default: 
                        return null;
                    }
            } else if (this.props.type === CUSTOMER_MODAL_TYPE.BRANCH) {
                    switch (this.props.action) {
                        case CUSTOMER_MODAL_ACTION.CREATE:
                            updateValues = {
                                ...updateValues,
                                customer_branch_name: {                 
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_name, 
                                    value: '' 
                                },
                                customer_branch_address: {              
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_address, 
                                    value: '' 
                                },
                                customer_branch_address_additional: {   
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_address_additional, 
                                    value: '' 
                                },
                                customer_branch_zip: {                  
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_zip, 
                                    value: '' 
                                },
                                customer_branch_city: {                 
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_city, 
                                    value: '' 
                                },
                                // customer_branch_state: {                
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_state, 
                                //     value: '' 
                                // },
                                customer_branch_country: {              
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_country, 
                                    value: '' 
                                },
                                customer_branch_phone: {                
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_phone, 
                                    value: '' 
                                },
                                customer_branch_mail: {                 
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_mail, 
                                    value: '' 
                                },
                                // customer_branch_web: {                  
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_web, 
                                //     value: '' 
                                // },
                                // customer_branch_tax_number: {           
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_tax_number, 
                                //     value: '' 
                                // },
                                // customer_branch_vat_number: {           
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.CREATE].customer_branch_vat_number, 
                                //     value: '' 
                                // },
                            }
                            break;
                        case CUSTOMER_MODAL_ACTION.UPDATE:
                            updateValues = {
                                ...updateValues,
                                customer_branch_id: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_id,
                                    value: (this.props.content.customer_branch_id)
                                },
                                customer_branch_name: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_name,
                                    value: (this.props.content.customer_branch_name)
                                },
                                customer_branch_address: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_address,
                                    value: (this.props.content.customer_branch_address || '')
                                },
                                customer_branch_address_additional: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_address_additional,
                                    value: (this.props.content.customer_branch_address_additional || '')
                                },
                                customer_branch_zip: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_zip,
                                    value: (this.props.content.customer_branch_zip || '')
                                },
                                customer_branch_city: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_city,
                                    value: (this.props.content.customer_branch_city || '')
                                },
                                // customer_branch_state: {
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_state,
                                //     value: (this.props.content.customer_branch_state || undefined)
                                // },
                                customer_branch_country: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_country,
                                    value: (this.props.content.customer_branch_country || '')
                                },
                                customer_branch_phone: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_phone,
                                    value: (this.props.content.customer_branch_phone || '')
                                },
                                customer_branch_mail: {
                                    ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_mail,
                                    value: (this.props.content.customer_branch_mail || '')
                                }
                                // customer_branch_web: {
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_web,
                                //     value: (this.props.content.customer_branch_web || undefined)
                                // },
                                // customer_branch_tax_number: {
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_tax_number,
                                //     value: (this.props.content.customer_branch_tax_number || undefined)
                                // },
                                // customer_branch_vat_number: {
                                //     ...this.state[CUSTOMER_MODAL_TYPE.BRANCH][CUSTOMER_MODAL_ACTION.UPDATE].customer_branch_vat_number,
                                //     value: (this.props.content.customer_branch_vat_number || undefined)
                                // },
                            }
                            break;
                        default:
                            return null;
                    }
            } else this.props.close();
            this.setState((state) =>({
                [this.props.type]: {
                    ...state[this.props.type],
                    [this.props.action]: updateValues
                }
            }));
        }
    }

    buildSubmitObject = () => {
        let obj = {};
        for(let key in this.state[this.props.type][this.props.action]) {
            if(!this.state[this.props.type][this.props.action][key].touched) continue;
            else obj = { ...obj, [key]: this.state[this.props.type][this.props.action][key].value };
        }
        return obj;
    }

    
    
    submitHandler = () => {
        const values = this.buildSubmitObject();
        if(Object.keys(values).length >= 1) {
            this.props.modalHandler(this.props.type, this.props.action, values, this.props.close)
        }
    }
    
    deleteHandler = () => {
        let delMsg;
        switch(this.props.type){
            case CUSTOMER_MODAL_TYPE.CUSTOMER:
                delMsg = `Möchten Sie den ausgewählten Kunden wirklich löschen?!\nAlle verknüften Kontakte und Standorte werden dadurch ebenfalls entfernt.\nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;
                break;
            case CUSTOMER_MODAL_TYPE.BRANCH:
                delMsg = `Möchten Sie den ausgewählen Kundenstandort wirklich löschen? \nAchtung, diese Aktion kann nicht Rückgängig gemacht werden!\n`;
                break;
            default: 
                delMsg = `Möchten Sie Ihre Auswahl endgültig löschen? \nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;
                break;
        }
        let delDialog = window.confirm(delMsg);
        const values = this.buildSubmitObject();
        if (delDialog) this.props.modalHandler(this.props.type, CUSTOMER_MODAL_ACTION.DELETE, values, this.props.close);
    }
    

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.action !== this.props.action ||
        prevProps.type !== this.props.type || 
        prevProps.content !== this.props.content ) this.mapValuesToState();

    }


    render() {
        let formElements = [];
        let formContent = null;
        let formHeadline = "";
        let modal = null;

        if(this.props.action !== null && this.props.type !== null) {
            // No Functional Code above this If-Check!
            for(let key in this.state[this.props.type][this.props.action]) {
                formElements.push({
                    id: key,
                    config:  this.state[this.props.type][this.props.action][key]
                })
            }
            if(this.props.loading) formContent = <SimpleLoader />;
            else formContent = formElements.map(el => {
                return (
                <Input 
                    key={el.id}
                    value={el.config.value}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    css={el.config.css}
                    label={el.config.label}
                    changed={(event) => this.changeHandler(event, el.id)}
                    disabled={this.props.inProcessing}
                />
            )})

            formHeadline = CUSTOMER_MODAL_HEADLINE[this.props.type] + " " + CUSTOMER_MODAL_HEADLINE[this.props.action];
        
        } 
        // No Functional Code below this If-Check!

        if(this.props.show) 
            modal = (
                <div className="modal_parent" >
                <div className="modal">
                    <div className="modal_area">
                        <div className="modal_headline">
                            {formHeadline}
                        </div>
                            {formContent}
                            <div className="modal_functions">
                                {this.props.action === CUSTOMER_MODAL_ACTION.UPDATE ? 
                                (
                                <button className="basic_btn_icon" type="" value="Löschen" onClick={() => this.deleteHandler()}>
                                    <i className="material-icons">
                                        delete
                                    </i>
                                </button>
                                ) 
                                : null}
                                <button className="basic_btn_icon" type="reset"  value="Abbrechen" onClick={() => this.props.close()}>
                                    <i className="material-icons">
                                        close
                                    </i>
                                </button>
                                <button className="basic_btn_icon" type="submit" value="Speichern" onClick={() => this.submitHandler()}>
                                    <i className="material-icons">
                                        save
                                    </i>
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            );
    
        return modal;
    }
}

export default ModalCustomer;