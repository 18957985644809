/**
 * Topnav.js
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import React from "react";
import "./Topnav.css";
import { NavLink, Link } from "react-router-dom";
import { connect } from 'react-redux'; 
import { returnUserPictureURI } from "./../../utils/httpInstance";

// import userpic from "../../assets/tmp/1.jpg";

class Topnav extends React.Component {
    state = {
        showUserMenu: false
    }
    toggleUserMenu = () => this.setState(state => ({ showUserMenu: !state.showUserMenu }));
    closeUserMenu = () => this.setState({ showUserMenu: false });

    render() {
        let userMenu = null;
        if(this.state.showUserMenu) {
            userMenu = (
                        <div className="topnav_usermenu" onMouseLeave={() => this.closeUserMenu()}>
                            <div className="topnav_usermenu_navpoint topnav_usermenu_navpoint_text">
                               <span>Angemeldet als:<br/><b>{this.props.userGivenName} {this.props.userSurName}</b></span>
                            </div>
                            <Link to="/logout" 
                                className="topnav_usermenu_navpoint topnav_usermenu_navpoint_link">
                                <i className="material-icons">
                                    power_settings_new
                                </i>
                                Logout
                            </Link>
                        </div>
            );
        }



        return (
            <div className="frame_topnav_area">
                <div className="topnav_title">KB Soft Web</div>
                <div className="topnav_navpoints">
                    <NavLink exact to="/">Dashboard</NavLink>
                    <NavLink to="/basedata">Stammdaten</NavLink>
                    <NavLink to="/user">Benutzer</NavLink>
                </div>
                <div className="topnav_user_log">
                        <div className="topnav_usermenu_container">
                        <img 
                            onMouseOver={() => this.toggleUserMenu()}
                            onClick={() => this.toggleUserMenu()}
							className="userpic_basic" 
							src={returnUserPictureURI(this.props.user_picture_file_name)}
							alt="" 
						/>
                        {userMenu}
                        </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        userGivenName: state.auth.user_given_name,
        userSurName: state.auth.user_sur_name,
		user_picture_file_name: state.auth.user_picture_file_name
	}
}


export default connect(mapStateToProps)(Topnav);
