/**
 * application.actions.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 28.01.2020
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import * as actionTypes from './../actionTypes';
import { store } from './../../index';


export const NOTIFICATION_TYPES = {
    INFO: "INFO",
    WARN: "WARN",
    ERROR: "ERROR",
}

 const add = (notificationType, text,) => {
     return {
         type: actionTypes.APP_NOTIFY_ADD,
         notificationType: notificationType,
         text: text,
     }
 }

 const dismiss = (id) => {
    const notification = store.getState().notification;
    if (notification[id] || false) {
        return {
            type: actionTypes.APP_NOTIFY_DISMISS,
            id: id
        }
    } else {
        // prevent app from crashing when element and eventhandler are already unmounted
        return {
            type: "APP_NOTIFY_BLANK"
        }
    }
 }

 const clear = () => {
     return {
         type: actionTypes.APP_NOTIFY_CLEARALL,
     }
 }

 export const notificationProvider = {
     add,
     dismiss,
     clear
 };

