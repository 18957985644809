/**
 * reviewgroup.reducer.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import * as actionTypes from './../actionTypes';
import {
    _updateObject
} from './../../utils/helper';

const initialState = {
    activeProcess: null,
    activeTestGroup: null,
    activeTestQuestion: null,
    activeTestFault: null,

    testGroup: {},
    testGroupLoading: false,
    testGroupError: null,

    testQuestion: {},
    testQuestionLoading: false,
    testQuestionError: null,

    testFault: {},
    testFaultLoading: false,
    testFaultError: null,

    testFaultAction: {},
    testFaultActionLoading: false,
    testFaultActionError: null,

    modalLoading: false,
    modalError: null,

    copyLoading: false,
    copyError: null,
};


const setActiveProcess = (state, action) => {
    return _updateObject(state, {
        activeProcess: action.activeProcess,
        activeTestGroup: null,
        testGroup: {},
        activeTestQuestion: null,
        testQuestion: {},
        activeTestFault: null,
        testFault: {},
        testFaultAction: {},
    })
}

const setActiveTestGroup = (state, action ) => {
    return _updateObject(state, {
        activeTestGroup: action.activeTestGroup,
        activeTestQuestion: null,
        testQuestion: {},
        activeTestFault: null,
        testFault: {},
        testFaultAction: {}
    })
}

const setActiveTestQuestion = (state, action ) => {
    return _updateObject(state, {
        activeTestQuestion: action.activeTestQuestion,
        activeTestFault: null,
        testFault: {},
        testFaultAction: {},
    })
}

const setActiveTestFault = (state, action ) => {
    return _updateObject(state, {
        activeTestFault: action.activeTestFault,
        testFaultAction: {}
    })
}

/**
 *  TEST GROUP
 */
const testGroupLoadingStart = (state, action ) => {
    return _updateObject(state, {
        testGroupLoading: true,
        testGroupError: null,
    })
}
const testGroupLoadingSuccess = (state, action ) => {
    return _updateObject(state, {
        testGroupLoading: false,
        testGroupError: null,
        testGroup: action.data
    })
}
const testGroupLoadingFail = (state, action ) => {
    return _updateObject(state, {
        testGroupLoading: false,
        testGroupError: action.error,
    })
}

/**
 *  TEST QUESTION
 */
const testQuestionLoadingStart = (state, action) => {
    return _updateObject(state, {
        testQuestionLoading: true,
        testQuestionError: null,
    })
}
const testQuestionLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        testQuestionLoading: false,
        testQuestionError: null,
        testQuestion: action.data
    })
}
const testQuestionLoadingFail = (state, action) => {
    return _updateObject(state, {
        testQuestionLoading: false,
        testQuestionError: action.error,
    })
}

/**
 *  TEST FAULT
 */
const testFaultLoadingStart = (state, action) => {
    return _updateObject(state, {
        testFaultLoading: true,
        testFaultError: null,
    })
}
const testFaultLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        testFaultLoading: false,
        testFaultError: null,
        testFault: action.data
    })
}
const testFaultLoadingFail = (state, action) => {
    return _updateObject(state, {
        testFaultLoading: false,
        testFaultError: action.error,
    })
}

/**
 *  TEST FAULT ACTION
 */
const testFaultActionLoadingStart = (state, action) => {
    return _updateObject(state, {
        testFaultActionLoading: true,
        testFaultActionError: null,
    })
}
const testFaultActionLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        testFaultActionLoading: false,
        testFaultActionError: null,
        testFaultAction: action.data
    })
}
const testFaultActionLoadingFail = (state, action) => {
    return _updateObject(state, {
        testFaultActionLoading: false,
        testFaultActionError: action.error,
    })
}

const modalStart = (state, action ) => {
    return _updateObject(state, {
        modalLoading: true,
        modalError: null,
    })
}
const modalSuccess = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: null,
    })
}
const modalFail = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: action.error,
    })
}

const copyStart = (state, action ) => {
    return _updateObject(state, {
        copyLoading: true,
        copyError: null
    })
}

const copySuccess = (state, action) => {
    return _updateObject(state, {
        copyLoading: false,
        copyError: null
    })
}

const copyFail = (state, action) => {
    return _updateObject(state, {
        copyLoading: false,
        copyError: action.error
    })
}

const reorderGroup = (state, action) => {
    return state;
}
const reorderQuestion = (state, action) => {
    return state;
}
const reorderFault = (state, action) => {
    return state;
}
const reorderAction = (state, action) => {
    return state;
}


const reviewgroupReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.REVIEWGROUP_SET_ACTIVE_PROCESS :               return setActiveProcess(state, action);
        case actionTypes.REVIEWGROUP_SET_ACTIVE_TESTGROUP :             return setActiveTestGroup(state, action);
        case actionTypes.REVIEWGROUP_SET_ACTIVE_TESTQUESTION :          return setActiveTestQuestion(state, action);
        case actionTypes.REVIEWGROUP_SET_ACTIVE_TESTFAULT :             return setActiveTestFault(state, action);

        case actionTypes.REVIEWGROUP_TESTGROUP_LOADING_START:           return testGroupLoadingStart(state, action);
        case actionTypes.REVIEWGROUP_TESTGROUP_LOADING_SUCCESS:         return testGroupLoadingSuccess(state, action);
        case actionTypes.REVIEWGROUP_TESTGROUP_LOADING_FAIL:            return testGroupLoadingFail(state, action);

        case actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_START:        return testQuestionLoadingStart(state, action);
        case actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_SUCCESS:      return testQuestionLoadingSuccess(state, action);
        case actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_FAIL:         return testQuestionLoadingFail(state, action);

        case actionTypes.REVIEWGROUP_TESTFAULT_LOADING_START:           return testFaultLoadingStart(state, action);
        case actionTypes.REVIEWGROUP_TESTFAULT_LOADING_SUCCESS:         return testFaultLoadingSuccess(state, action);
        case actionTypes.REVIEWGROUP_TESTFAULT_LOADING_FAIL:            return testFaultLoadingFail(state, action);

        case actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_START:     return testFaultActionLoadingStart(state, action);
        case actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_SUCCESS:   return testFaultActionLoadingSuccess(state, action);
        case actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_FAIL:      return testFaultActionLoadingFail(state, action);

        case actionTypes.REVIEWGROUP_MODAL_START:                       return modalStart(state, action);
        case actionTypes.REVIEWGROUP_MODAL_SUCCESS:                     return modalSuccess(state, action);
        case actionTypes.REVIEWGROUP_MODAL_FAIL:                        return modalFail(state, action);

        case actionTypes.REVIEWGROUP_COPY_START:                        return copyStart(state, action);
        case actionTypes.REVIEWGROUP_COPY_SUCCESS:                      return copySuccess(state, action);
        case actionTypes.REVIEWGROUP_COPY_FAIL:                         return copyFail(state, action);

        case actionTypes.REVIEWGROUP_REORDER_TESTGROUP:                 return reorderGroup(state,action);
        case actionTypes.REVIEWGROUP_REORDER_TESTQUESTION:              return reorderQuestion(state,action);
        case actionTypes.REVIEWGROUP_REORDER_TESTFAULT:                 return reorderFault(state,action);
        case actionTypes.REVIEWGROUP_REORDER_TESTFAULTACTION:           return reorderAction(state,action);

        default:
            return state;
    }
}

export default reviewgroupReducer;
