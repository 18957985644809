import React from 'react';
import './ReviewGroups.css';
import * as actions from './../../../store/index';
import { connect } from 'react-redux';
import Loader from './../../../components/Loader/Loader';
import ModalReviewGroups, { GROUPS_MODAL_ACTION, GROUPS_MODAL_TYPE } from './ModalReviewGroups';
import ReviewGroupsWrapper from './ReviewGroupsWrapper';

const TestGroupIDSonstiges = 1
// const TestQuestionIDSonstiges = 22


// GROUP TYPES USED BY COPY AND DRAG AND DROP!!!
export const GROUPS_COPY_TYPE = {
	GROUP: 		"GROUP",
	QUESTION: 	"QUESTION",
	FAULT: 		"FAULT",
	ACTION: 	"ACTION"
};

export const NoElementsFound = (props) => {
    return (
        <div className={"reviewgroups_group_navpoint_no_elements"} >
            <div className="reviewgroups_listitem_text">
                {props.name}
            </div>
        </div>
    );
}

class ReviewGroups extends React.Component {
    constructor(){
        super();
        this.state = {
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null,
        };
    }
    componentDidMount () {
        if(!this.props.testGroupLoading) this.props.loadGroups(this.props.match.params.processId);
        document.title = "KB SOFT - Prüfgruppen";
    }

    openModal = (type, action, content) => {
        this.setState({
            showModal: true,
            modalType: type,
            modalAction: action,
            modalContent: content
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null
        })
    }

  

    render () {
        console.log("render");
		let copyInProgressLoader = null;
        let testGroupArray = [];
        let testQuestionArray = [];
        let testFaultArray = [];
        let testFaultActionArray = [];
        
        if (this.props.process_id !== null && this.props.testGroup) {
            for(let key in this.props.testGroup) {
                testGroupArray.push({
                    key: "GROUP_"+key,
                    ...this.props.testGroup[key]
                })
            }
            testGroupArray = testGroupArray.sort((a, b) => {
                if (a.test_group_order_id > b.test_group_order_id) return 1;
                if (a.test_group_order_id < b.test_group_order_id) return -1;
                return 0;
            });
        }
        if (this.props.test_group_id !== null && this.props.testQuestion) {
            for(let key in this.props.testQuestion) {
                testQuestionArray.push({
                    key: "QUESTION_"+key,
                    ...this.props.testQuestion[key]
                })
            }
            testQuestionArray = testQuestionArray.sort((a, b) => {
                if (a.test_question_order_id > b.test_question_order_id) return 1;
                if (a.test_question_order_id < b.test_question_order_id) return -1;
                return 0;
            });
        }
        if (this.props.test_question_id !== null && this.props.testFault) {
            for(let key in this.props.testFault) {
                testFaultArray.push({
                    key: "FAULT_"+key,
                    ...this.props.testFault[key]
                })
            }
            testFaultArray = testFaultArray.sort((a, b) => {
                if (a.test_fault_order_id > b.test_fault_order_id) return 1;
                if (a.test_fault_order_id < b.test_fault_order_id) return -1;
                return 0;
            });
        }
        if (this.props.test_fault_id !== null && this.props.testFaultAction) {
            for(let key in this.props.testFaultAction) {
                testFaultActionArray.push({
                    key: "FAULTACTION_"+key,
                    ...this.props.testFaultAction[key]
                })
            }
            testFaultActionArray = testFaultActionArray.sort((a, b) => {
                if (a.test_fault_action_order_id > b.test_fault_action_order_id) return 1;
                if (a.test_fault_action_order_id < b.test_fault_action_order_id) return -1;
                return 0;
            });
        }


		if (this.props.copyLoading) {
            copyInProgressLoader = <Loader headline="Kopieren wird ausgeführt" subline="Dies kann einen Moment in Anspruch nehmen..." />;
        }


        return(
        <React.Fragment>
			{copyInProgressLoader}
            <ModalReviewGroups
                show={this.state.showModal}
                loading={this.props.modalLoading}
                close={() => this.closeModal()}
                action={this.state.modalAction}
                type={this.state.modalType}
                content={{...this.state.modalContent}}
                modalHandler={this.props.modalHandler}
                inProcessing={false}
            />
           <div className="reviewgroups_content_area">
            <ReviewGroupsWrapper
                containerName="Prüfgruppe"
                enableAddFunction={this.props.process_id }
                addFunction={() => this.openModal(GROUPS_MODAL_TYPE.TEST_GROUP, GROUPS_MODAL_ACTION.CREATE, {})}
                additionalStyling={this.state.dragging ? () => this.getContainerDropStyling(GROUPS_COPY_TYPE.GROUP) : () => "" }
                childrenType={GROUPS_COPY_TYPE.GROUP}
                childrenArray={testGroupArray}
                childrenLoading={this.props.testGroupLoading}
                childrenActiveProp={this.props.test_group_id}
                childrenCopyClick={this.props.copyHandler}
                childrenEditClick={this.openModal}
                childrenClick={(elId) => this.props.loadQuestions(this.props.process_id, elId)}
                persistDragOrder={this.props.updateOrdering}
            />

            <ReviewGroupsWrapper
                containerName="Prüffragen"
                enableAddFunction={this.props.test_group_id !== TestGroupIDSonstiges}
                addFunction={() => this.openModal(GROUPS_MODAL_TYPE.TEST_QUESTION, GROUPS_MODAL_ACTION.CREATE, {})}
                additionalStyling={this.state.dragging ? () => this.getContainerDropStyling(GROUPS_COPY_TYPE.QUESTION) : () => "" }
                childrenType={GROUPS_COPY_TYPE.QUESTION}
                childrenArray={testQuestionArray}
                childrenLoading={this.props.testQuestionLoading}
                childrenActiveProp={this.props.test_question_id}
                childrenCopyClick={this.props.copyHandler}
                childrenEditClick={this.openModal}
                childrenClick={(elId) => this.props.loadFaults(this.props.process_id, this.props.test_group_id, elId)}
                persistDragOrder={this.props.updateOrdering}
    
            />
            
            <ReviewGroupsWrapper
                containerName="Fehler"
                enableAddFunction={this.props.test_question_id}
                addFunction={() => this.openModal(GROUPS_MODAL_TYPE.TEST_FAULT, GROUPS_MODAL_ACTION.CREATE, {})}
                additionalStyling={this.state.dragging ? () => this.getContainerDropStyling(GROUPS_COPY_TYPE.FAULT) : () => "" }
                childrenArray={testFaultArray}
                childrenType={GROUPS_COPY_TYPE.FAULT}
                childrenLoading={this.props.testFaultLoading}
                childrenActiveProp={this.props.test_fault_id}
                childrenCopyClick={this.props.copyHandler}
                childrenEditClick={this.openModal}
                childrenClick={(elId) => this.props.loadFaultActions(this.props.process_id, this.props.test_group_id, this.props.test_question_id, elId)}
                persistDragOrder={this.props.updateOrdering}
            />
             
            <ReviewGroupsWrapper
                containerName="Maßnahmen"
                enableAddFunction={this.props.test_fault_id}
                addFunction={() => this.openModal(GROUPS_MODAL_TYPE.TEST_ACTION, GROUPS_MODAL_ACTION.CREATE, {})}
                additionalStyling={this.state.dragging ? () => this.getContainerDropStyling(GROUPS_COPY_TYPE.ACTION) : () => "" }
                childrenType={GROUPS_COPY_TYPE.ACTION}
                childrenArray={testFaultActionArray}
                childrenLoading={this.props.testFaultActionLoading}
                childrenActiveProp={this.props.test_fault_action_id}
                childrenCopyClick={this.props.copyHandler}
                childrenEditClick={this.openModal}
                childrenClick={(elId) => void(0)}
                persistDragOrder={this.props.updateOrdering}
            />
           </div>
           </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        process_id:             state.reviewgroup.activeProcess,
        test_group_id:          state.reviewgroup.activeTestGroup,
        test_question_id:       state.reviewgroup.activeTestQuestion,
        test_fault_id:          state.reviewgroup.activeTestFault,
        testGroup:              state.reviewgroup.testGroup,
        testQuestion:           state.reviewgroup.testQuestion,
        testFault:              state.reviewgroup.testFault,
        testFaultAction:        state.reviewgroup.testFaultAction,
        testGroupLoading:       state.reviewgroup.testGroupLoading,
        testQuestionLoading:    state.reviewgroup.testQuestionLoading,
        testFaultLoading:       state.reviewgroup.testFaultLoading,
        testFaultActionLoading: state.reviewgroup.testFaultActionLoading,
		modalLoading:           state.reviewgroup.modalLoading,
		copyLoading: 			state.reviewgroup.copyLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadGroups: (process_id) => dispatch(actions.loadGroups(process_id)),
        loadQuestions: (process_id, test_group_id) => dispatch(actions.loadQuestions(process_id, test_group_id)),
        loadFaults: (process_id, test_group_id, test_question_id) => dispatch(actions.loadFaults(process_id, test_group_id, test_question_id)),
        loadFaultActions: (process_id, test_group_id, test_question_id, test_fault_id) => dispatch(actions.loadFaultActions(process_id, test_group_id, test_question_id, test_fault_id)),
		modalHandler: (type, action, values, callback) => dispatch(actions.reviewGroupsModalHandler(type, action, values, callback)),
        copyHandler: (type, values) => dispatch(actions.copyReviewGroupsHandler(type, values)),
        updateOrdering: (type, array, cb) => dispatch(actions.updateOrdering(type, array, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewGroups);
