/**
 * ProcessSelection.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from 'react';
import {Redirect } from 'react-router-dom';

class ProcessSelection extends React.Component {
    render(){
        return(
            <div>
                <Redirect to={this.props.match.url + "/1/reviewgroups"} />
            </div>
        );
    }
}

export default ProcessSelection;