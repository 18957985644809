/**
 * axiosErrorHandler.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 28.01.2020
 * Copyright © digiply GmbH. All rights reserved.
 *
 */

/**
 * Function to handle the Axios error object for request or response errors
 * @param {object} error the original axios error object
 * @param {string} fallbackMessage a fallbackmessage to be shown when no error object property is reached
 * @returns {string} the error message
 */
export const axiosErrorHandler = (error, fallbackMessage) => {
    if(error) {

        if(error.response) {
            // The request was made and the server responded with a statuscode that falls out of 2xx range
            return error.response.data.msg || fallbackMessage || "";
        } else if (error.request) {
            // The request was made but no response was received
            return "Die Anfrage konnte nicht ausgeführt werden."
        } else {
            // something else triggered the error
            return "Die Anfrage konnte nicht ausgeführt werden."
        }
    } else return fallbackMessage || "";
}
