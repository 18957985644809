/**
 * App.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import React from "react";
import "./App.css";
import { _decodeToken } from "./utils/helper";
// Router Imports
import { Switch, Route, Redirect } from "react-router-dom";
// Redux Imports
import { connect } from "react-redux";

// Component Imports
import Login from "./container/Login/Login";
// import SubmitNewPassword from "./container/SubmitNewPassword/SubmitNewPassword";
// import ForgotPassword from "./container/ForgotPassword/ForgotPassword";

// Protected Components Imports
import MainContent from "./container/MainContent/MainContent";
import Dashboard from "./container/Dashboard/Dashboard";
import MasterData from "./container/MasterData/MasterData";
import UserData from "./container/UserData/UserData";
import Logout from "./container/Logout/Logout";
import NoMatch from "./container/NoMatch/NoMatch";

class App extends React.Component {
    render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={props => (this.props.isAuth ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />)} />

    );

        return (
            <div className="frame_content">
                <Switch>
                    <Route exact path="/login" component={Login} />
                    {/* <Route exact path="/forgotpassword" component={ForgotPassword} /> */}
                    {/* <Route exact path="/submitnewpassword" component={SubmitNewPassword} /> */}

                    <MainContent>
                        <Switch>
                            <PrivateRoute path="/basedata" component={MasterData} />
                            <PrivateRoute path="/user" component={UserData} />
                            <PrivateRoute exact path="/logout" component={Logout} />
                            <PrivateRoute exact path="/" component={Dashboard} />
                            <PrivateRoute component={NoMatch} />
                        </Switch>
                    </MainContent>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: _decodeToken(state.auth.accessToken)
    };
};

export default connect(mapStateToProps)(App);
