/**
 * httpInstance.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import axios from "axios";
import { store } from './../index';
import defaultUserPicture from './../assets/tmp/1.jpg';
const BASE_URI = window._KB_ENDPOINT + '/api';
const REQ_TIMEOUT = 15000;


export const httpRequest = () => {
    let token = store.getState().auth.accessToken;
    return axios.create({
        baseURL: BASE_URI,
        timeout: REQ_TIMEOUT,
        headers: {
            'X-AT': token,
        }
    });
};

export const httpMultipartRequest = () => {
    let token = store.getState().auth.accessToken;
    return axios.create({
        baseURL: BASE_URI,
        timeout: 30000,
        headers: {
            'X-AT': token,
            'Content-Type': 'multipart/form-data;'
        }
    });
};

export const returnUserPictureURI = (pictureHash) => {
	let resourceToken = store.getState().auth.resourceToken;
	let imagePath = defaultUserPicture;
	if(pictureHash) {
		imagePath = BASE_URI + "/resource/user/" + pictureHash + "?a=" + resourceToken;
	}
	return imagePath;
}
