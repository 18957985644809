/**
 * customer.reducer.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

 import * as actionTypes from '../actionTypes';
import {
    _updateObject
} from '../../utils/helper';

const initialState = {
    user: {},
    userLoading: false,
    userError: null,

    modalLoading: false,
    modalError: null
};

//---- User

const userLoadingStart = (state, action) => {
    return _updateObject(state, {
        userLoading: true,
        userError: action.error,
    });
}
const userLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        user: action.data,
        userLoading: false,
        userError: null,
    });
}
const userLoadingFail = (state, action) => {
    return _updateObject(state, {
        userLoading: false,
        userError: action.error
    });
}

//---- Modal

const modalStart = (state, action ) => {
    return _updateObject(state, {
        modalLoading: true,
        modalError: false,
    })
}
const modalSuccess = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: false,
    })
}
const modalFail = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: action.error,
    })
}

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.USER_LOADING_START:       return userLoadingStart(state, action);
        case actionTypes.USER_LOADING_SUCCESS:     return userLoadingSuccess(state, action);
        case actionTypes.USER_LOADING_FAIL:        return userLoadingFail(state, action);

        case actionTypes.USER_MODAL_START:         return modalStart(state, action);
        case actionTypes.USER_MODAL_SUCCESS:       return modalSuccess(state, action);
        case actionTypes.USER_MODAL_FAIL:          return modalFail(state, action);

        default: 
            return state;
    }
}

export default userReducer;
