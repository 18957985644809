import * as actionTypes from './../actionTypes';
import { httpRequest, httpMultipartRequest } from './../../utils/httpInstance';
import { changeUserData } from './auth.actions';
import { store } from './../../index';
import { 
    _editObjectChildByKey, 
} from './../../utils/helper';
import {
	USER_MODAL_TYPE,
	USER_MODAL_ACTION,
}from './../../container/UserData/ModalUser';

let ENDPOINT = window._KB_ENDPOINT;

//----User

const userLoadingStart = () => {
    return {
        type: actionTypes.USER_LOADING_START
    }
}

const userLoadingSuccess = (data) => {
    return {
        type: actionTypes.USER_LOADING_SUCCESS,
        data: data,
    }
}

const userLoadingFail = (e) => {
    return {
        type: actionTypes.USER_LOADING_FAIL,
        error: e
    }  
}

//----Modal

const modalStart = () => {
    return {
        type: actionTypes.USER_MODAL_START
    }
} 

const modalSuccess = () => {
    return {
        type: actionTypes.USER_MODAL_SUCCESS
    }
} 

const modalFail = (error) => {
    return {
        type: actionTypes.USER_MODAL_FAIL,
        error: error
    }
}


export const loadUser = () => {
    return dispatch => {
        dispatch(userLoadingStart());
        httpRequest().get('/user/')
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].user_id]: r.data[key]
                }
            }
            dispatch(userLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(userLoadingFail(e));
        })
    }
}

const createUser = (state, values, dispatch, file) => new Promise((resolve, reject) => {
	httpRequest().post('/user/', values)
	.then(r => new Promise((resolve, reject) => {
		if(file.newPicture) {
			let data = new FormData();
			let pictureName = `UP-USRPICWEBAPI-${(new Date().getTime() /1000)}`;
			data.append('userimage', file.data, pictureName);
			httpMultipartRequest()
			.post(`${ENDPOINT}/api/user/${r.data.insertId}/avatar`, data, {})
			.then(result => {
				resolve(result);
			})
			.catch(e => {
				reject(e);
			});
		} else resolve(r);
	}))
	.then(r => {	
		return httpRequest().get('/user/');
	})
	.then(r => {
		let data = {};
		for (let key in r.data) {
			data = {
				...data,
				[r.data[key].user_id]: r.data[key]
			}
		}
		dispatch(userLoadingSuccess(data));
		resolve('ok');
	})
	.catch(e =>{
		console.log(e);
		reject(e);
	})
});

const editUser = (state, values, dispatch, file) => new Promise((resolve, reject) => {
	if(!values.user_id) reject('parameter missing');
	else {
		let userId = values.user_id;
		let isCurrentUser = userId === state.auth.user_id;
		// File new / edit / delete
		// Values & File
		if(Object.keys(values).length>1) {
			// values must be submitted // additinally check if file is provided or deleted
			httpRequest().patch(`/user/${userId}`, values)
			.then( r => {
				let filePromise;
				let data = new FormData();
				let pictureName = `UP-USRPICWEBAPI-${(new Date().getTime() /1000)}`;
				if (file.newPicture && !file.editPicture && !file.deletePicture) {
					// Submit a brand new Picture. No Picture was provided before
					data.append('userimage', file.data, pictureName);
					filePromise = httpMultipartRequest().post(`${ENDPOINT}/api/user/${userId}/avatar`, data, {})
				} else if (!file.newPicture && file.editPicture && !file.deletePicture) {
					// Exchange Picture
					data.append('userimage', file.data, pictureName);
					filePromise = httpMultipartRequest().patch(`${ENDPOINT}/api/user/${userId}/avatar`, data, {})
				} else if (!file.newPicture && !file.editPicture && file.deletePicture) {
					// Delete Picture
					filePromise = httpRequest().delete(`${ENDPOINT}/api/user/${userId}/avatar`)
				} else {
					filePromise = () => new Promise((resolve) => { resolve(r)});
				}
				return filePromise;
			})
			.then(r => {
				return httpRequest().get(`/user/${userId}`);
			})
			.then(response => {
				console.log(response);
				let userObject = state.user.user;
				let newUser = _editObjectChildByKey(userObject, response.data.user_id, response.data);
				dispatch(userLoadingSuccess(newUser));
				if (isCurrentUser) dispatch(changeUserData(response.data));
				resolve('ok');
			})
			.catch(e => {
				console.log(e);
				reject(e);
			})
		} else {
			let filePromise;
			let data = new FormData();
			let pictureName = `UP-USRPICWEBAPI-${(new Date().getTime() /1000)}`;
			if (file.newPicture && !file.editPicture && !file.deletePicture) {
				// Submit a brand new Picture. No Picture was provided before
				data.append('userimage', file.data, pictureName);
				filePromise = () => httpMultipartRequest().post(`${ENDPOINT}/api/user/${userId}/avatar`, data, {})
			} else if (!file.newPicture && file.editPicture && !file.deletePicture) {
				// Exchange Picture
				data.append('userimage', file.data, pictureName);
				filePromise = () => httpMultipartRequest().patch(`${ENDPOINT}/api/user/${userId}/avatar`, data, {})
			} else if (!file.newPicture && !file.editPicture && file.deletePicture) {
				// Delete Picture
				filePromise = () => httpRequest().delete(`${ENDPOINT}/api/user/${userId}/avatar`)
			} else return resolve('ok');

			filePromise()
			.then(r => {
				return httpRequest().get(`/user/${userId}`);
			})
			.then(response => {
				let userObject = state.user.user;
				let newUser = _editObjectChildByKey(userObject, response.data.user_id, response.data);
				dispatch(userLoadingSuccess(newUser));
				if (isCurrentUser) dispatch(changeUserData(response.data));
				resolve('ok');
			})
			.catch(e => {
				console.log(e);
				reject(e);
			})
		}
	}		
});

const deleteUser = (state, values, dispatch) => new Promise((resolve, reject) => {
	if(!values.user_id) reject('no id provided')
	else {
		httpRequest().delete(`/user/${values.user_id}`)
			.then(r => {
				return httpRequest().get('/user/');
			})
			.then(r => {
				let data = {};
				for (let key in r.data) {
					data = {
						...data,
						[r.data[key].user_id]: r.data[key]
					}
				}
				dispatch(userLoadingSuccess(data));
				resolve('ok');
			})
			.catch(e => {
				console.log(e);
				reject(e);
			})
	}
});

const updatePassword = (state, values, dispatch) => new Promise((resolve, reject) => {
	if(!values.user_id) reject('no id provided');
	else {
		httpRequest().post(`/user/${values.user_id}/pswdrst`, values)
		.then(r => {
			resolve('ok');
		})
		.catch(e => {
			console.log(e);
			reject(e);
		})

	}
});


export const handleUserModal = (type, action, values, closeModalCallback, file) => {
	let state = store.getState();
	let func;
	if (type === USER_MODAL_TYPE.USER && action === USER_MODAL_ACTION.CREATE ) func = createUser;
	else if (type === USER_MODAL_TYPE.USER && action === USER_MODAL_ACTION.UPDATE ) func = editUser;
	else if (type === USER_MODAL_TYPE.USER && action === USER_MODAL_ACTION.DELETE ) func = deleteUser;
	else if (type === USER_MODAL_TYPE.PASSWORD && action === USER_MODAL_ACTION.UPDATE ) func = updatePassword;
	else func = () => new Promise(resolve => resolve(true));
	
	return dispatch => {
		dispatch(modalStart());
		func(state, values, dispatch, file)
		.then(r => {
				dispatch(modalSuccess());
				closeModalCallback();
		})
		.catch(e => {
			console.log(e);
			dispatch(modalFail(e));
			closeModalCallback();
		})
	}
}