/**
 * NotificatonCenter.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 28.01.2020
 * Copyright © digiply GmbH. All rights reserved.
 *
 */
import React from 'react';
import './NotificationCenter.css';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { notificationProvider, NOTIFICATION_TYPES } from './../../store/actions/notification.actions';


const InfoIcon = () => <i className="material-icons notification_item_icon_info">info</i>;
const WarningIcon = () => <i className="material-icons notification_item_icon_warn">warning</i>;
const ErrorIcon = () => <i className="material-icons notification_item_icon_error">error</i>;


/**
 * Noficiation Item
 * @param {string} props.type indicates the notification type
 * @param {string} props.text the displayed message inside the notification
 * @param {function} props.click the function triggered on dismissclick
 */
const NotificationItem = (props) => {
    let disappearcount = 10000;

    let iconOutput = null;
    switch (props.type) {
        case NOTIFICATION_TYPES.INFO:
            iconOutput = <InfoIcon />;
            disappearcount = 5000;
            break;
        case NOTIFICATION_TYPES.WARN:
            iconOutput = <WarningIcon />;
            break;
        case NOTIFICATION_TYPES.ERROR:
            iconOutput = <ErrorIcon />;
            break;
        default:
            iconOutput = null;
            disappearcount = 0;
            break;
    }

    setTimeout(() => props.click(props.id), disappearcount);
    return (
        <div className="notification_item">
            <div className="notification_item_icon">
                {iconOutput}
            </div>
            <div className="notification_text_container">
            <div className="notification_close_btn_container">
                <button type="button" className="basic_btn_icon" onClick={() => props.click(props.id)}>
                    <i className="material-icons">close</i>
                </button>
            </div>
            <div className="notification_content">
                {props.text}
            </div>
            </div>

        </div>
    );
}


class NotificationCenter extends React.PureComponent {
    render() {
        let array = [];
        let count = 0;
        for (let key in this.props.notifications) {
            array.push({
                id: key,
                ...this.props.notifications[key]
            })
            count++;
            // limit displayed notifications to 5
            if(count > 5) break;
        }
        let notifications;
        if (array.length > 0) {
            notifications = array.map(item => (
                <NotificationItem
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    text={item.text}
                    click={this.props.dismissNotification}
                />
            ))
        } else {
            notifications = null
        }

        return(
            <div className="notification_center_area">
                <CSSTransitionGroup
                    transitionName="notification"
                    transitionEnterTimeout={400}
                    transitionLeaveTimeout={400}
                >
                    {notifications}
                </CSSTransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dismissNotification: (id) => dispatch(notificationProvider.dismiss(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
