/**
 * index.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Router Imports
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

// Redux Import
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

// Reducer Import
import notificationReducer from './store/reducer/notification.reducer';
import authReducer from "./store/reducer/auth.reducer";
import reviewgroupReducer from './store/reducer/reviewgroup.reducer';
import customerReducer from './store/reducer/customer.reducer';
import applicationReducer from './store/reducer/application.reducer';
import userReducer from './store/reducer/user.reducer';
// Create HistoryObject
export const history = createBrowserHistory();

// Generate Root Reducer
const rootReducer = combineReducers({
    notification: notificationReducer,
    auth: authReducer,
    reviewgroup: reviewgroupReducer,
    customer: customerReducer,
    application: applicationReducer,
    user: userReducer
});

// Redux DevTools Chrome
const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25
        })) ||
    compose;

//Create Redux Store
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

// Main Provider
const app = (
    <Provider store={store}>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
