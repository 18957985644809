/**
 * customer.reducer.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */
import * as actionTypes from './../actionTypes';
import { httpRequest } from './../../utils/httpInstance';
import { store } from './../../index';
import { notificationProvider, NOTIFICATION_TYPES } from './notification.actions';
import { axiosErrorHandler } from '../../utils/axiosErrorHandler';

import {
    _editObjectChildByKey,
} from './../../utils/helper';

import {
    CUSTOMER_MODAL_ACTION,
    CUSTOMER_MODAL_TYPE
} from './../../container/MasterData/Customers/ModalCustomer';

import {
    CONTACT_MODAL_ACTION,
    CONTACT_MODAL_TYPE
} from './../../container/MasterData/Contacts/ModalContacts';

const setActiveCustomer = (customer_id) => {
    return {
        type: actionTypes.CUSTOMER_SET_ACTIVE_CUSTOMER,
        activeCustomer: customer_id,
    }
}

//----Customer

const customerLoadingStart = () => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_LOADING_START
    }
}

const customerLoadingSuccess = (data) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_LOADING_SUCCESS,
        data: data,
    }
}

const customerLoadingFail = (e) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_LOADING_FAIL,
        error: e
    }
}

//----CustomerBranch

const customerBranchLoadingStart = () => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_START
    }
}

const customerBranchLoadingSuccess = (data) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_SUCCESS,
        data: data,
    }
}

const customerBranchLoadingFail = (e) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_FAIL,
        error: e
    }
}

//----CustomerContact

const customerContactLoadingStart = () => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_START
    }
}

const customerContactLoadingSuccess = (data) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_SUCCESS,
        data: data,
    }
}

const customerContactLoadingFail = (e) => {
    return {
        type: actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_FAIL,
        error: e
    }
}

//----Modal

const modalStart = () => {
    return {
        type: actionTypes.CUSTOMER_MODAL_START
    }
}

const modalSuccess = () => {
    return {
        type: actionTypes.CUSTOMER_MODAL_SUCCESS
    }
}

const modalFail = (error) => {
    return {
        type: actionTypes.CUSTOMER_MODAL_FAIL,
        error: error
    }
}




export const loadCustomers = () => {
    return dispatch => {
        dispatch(customerLoadingStart());
        httpRequest().get('/basedata/customer/')
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].customer_id]: r.data[key]
                }
            }
            dispatch(customerLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(customerLoadingFail(e));
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, "Kunden konnten nicht geladen werden, bitte versuchen Sie es erneut."))
        })
    }
}


export const loadCustomerBranches = (customer_id) => {
    return dispatch => {
        dispatch(setActiveCustomer(customer_id));
        dispatch(customerBranchLoadingStart());
        httpRequest().get(`/basedata/customer/${customer_id}/branch/`)
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].customer_branch_id]: r.data[key]
                }
            }
            dispatch(customerBranchLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(customerBranchLoadingFail(e));
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, "Standorte konnten nicht geladen werden, bitte versuchen Sie es erneut."))
        })
    }
}

const createCustomer = (state, values, dispatch) => new Promise((resolve, reject) => {
    if(values.customer_name) {
        let url = `/basedata/customer/`
        httpRequest().post(url, values)
        .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].customer_id]: r.data[key]
                    }
                }
                dispatch(customerLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('customer_name missing');
});

const editCustomer = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (values.customer_id && values.customer_name) {
        let url = `/basedata/customer/${values.customer_id}`
        httpRequest().patch(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let customerObject = state.customer.customer;
                let newCustomer = _editObjectChildByKey(customerObject, r.data.customer_id, r.data);
                dispatch(customerLoadingSuccess(newCustomer));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('customer_name missing');
});

const deleteCustomer = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (values.customer_id) {
        let url = `/basedata/customer/${values.customer_id}`
        httpRequest().delete(url)
            .then(r => {
                let url2 = `/basedata/customer/`
                return httpRequest().get(url2);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].customer_id]: r.data[key]
                    }
                }
                dispatch(setActiveCustomer(null));
                dispatch(customerLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('customer_name missing');
});

const createBranch = (state, values, dispatch) => new Promise((resolve, reject) => {
    // If Customer Name is not set, take customer city name
    // if name and city are not set, let request fail.
    if(!values.customer_branch_name && values.customer_branch_city) {
        values.customer_branch_name = values.customer_branch_city
    }



    if (values.customer_branch_name && state.customer.activeCustomer ) {
        let url = `/basedata/customer/${state.customer.activeCustomer}/branch/`
        httpRequest().post(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].customer_branch_id]: r.data[key]
                    }
                }
                dispatch(customerBranchLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('customer_name missing');
});

const editBranch = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (values.customer_branch_id && state.customer.activeCustomer) {
        let url = `/basedata/customer/${state.customer.activeCustomer}/branch/${values.customer_branch_id}`
        httpRequest().patch(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let branchObject = state.customer.customerBranch;
                let newBranch = _editObjectChildByKey(branchObject, r.data.customer_branch_id, r.data);
                dispatch(customerBranchLoadingSuccess(newBranch));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('customer_name missing');
});

const deleteBranch = (state, values, dispatch) => new Promise((resolve, reject) => {
 if (values.customer_branch_id && state.customer.activeCustomer) {
        let url = `/basedata/customer/${state.customer.activeCustomer}/branch/${values.customer_branch_id}`
            httpRequest().delete(url)
                .then(r => {
                    let url2 = `/basedata/customer/${state.customer.activeCustomer}/branch/`
                    return httpRequest().get(url2);
                })
                .then(r => {
                    let data = {};
                    for (let key in r.data) {
                        data = {
                            ...data,
                            [r.data[key].customer_branch_id]: r.data[key]
                        }
                    }

                    dispatch(customerBranchLoadingSuccess(data));
                    resolve('ok');
                })
                .catch(e => {
                    reject(e);
                })
        } else reject('customer_name missing');
});



export const customerModalHandler = (type, action, values, closeModalCallback) => {
    let state = store.getState();
    let func;
    if      (type === CUSTOMER_MODAL_TYPE.CUSTOMER && action === CUSTOMER_MODAL_ACTION.CREATE) func = createCustomer;
    else if (type === CUSTOMER_MODAL_TYPE.CUSTOMER && action === CUSTOMER_MODAL_ACTION.UPDATE) func = editCustomer;
    else if (type === CUSTOMER_MODAL_TYPE.CUSTOMER && action === CUSTOMER_MODAL_ACTION.DELETE) func = deleteCustomer;
    else if (type === CUSTOMER_MODAL_TYPE.BRANCH && action === CUSTOMER_MODAL_ACTION.CREATE) func = createBranch;
    else if (type === CUSTOMER_MODAL_TYPE.BRANCH && action === CUSTOMER_MODAL_ACTION.UPDATE) func = editBranch;
    else if (type === CUSTOMER_MODAL_TYPE.BRANCH && action === CUSTOMER_MODAL_ACTION.DELETE) func = deleteBranch;
    else func = () => new Promise((resolve) => {
        resolve(true)
    });

    return dispatch => {
        dispatch(modalStart());
        func(state, values, dispatch)
            .then(r => {
                    dispatch(modalSuccess());
                    closeModalCallback();
            })
            .catch(e => {
                dispatch(modalFail(e));
                closeModalCallback()
                const message = axiosErrorHandler(e, "")
                dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
            });
    }
}


export const loadContacts = (customer_id) => {
    return dispatch => {
        dispatch(setActiveCustomer(customer_id));
        dispatch(customerContactLoadingStart());
        httpRequest().get(`/basedata/customer/${customer_id}/branch/`)
        .then(r => {
            let data = {};
            for (let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].customer_branch_id]: r.data[key]
                }
            }
            dispatch(customerBranchLoadingSuccess(data));
            return httpRequest().get(`/basedata/customer/${customer_id}/contact/`)
        })
        .then(r => {
            let data = {};
            for (let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].customer_contact_id]: r.data[key]
                }
            };
            dispatch(customerContactLoadingSuccess(data));

        })
        .catch(e => {
            dispatch(customerContactLoadingFail(e));
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, "Kontakte konnten nicht geladen werden, bitte versuchen Sie es erneut."))
        })
    }
}

const createContact = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.customer.activeCustomer && values.application_title_id && values.customer_contact_given_name && values.customer_contact_sur_name) {
        let url = `/basedata/customer/${state.customer.activeCustomer}/contact/`
        httpRequest().post(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].customer_contact_id]: r.data[key]
                    }
                }
                dispatch(customerContactLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('parameter missing');
});

const editContact = (state, values, dispatch) => new Promise((resolve, reject) => {
    console.log(values);
    if (state.customer.activeCustomer && values.customer_contact_id) {
        let url = `/basedata/customer/${state.customer.activeCustomer}/contact/${values.customer_contact_id}`
        httpRequest().patch(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let contactObject = state.customer.customerContact;
                let newContact = _editObjectChildByKey(contactObject, r.data.customer_contact_id, r.data);
                dispatch(customerContactLoadingSuccess(newContact));
                console.log(r);
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject('parameter missing');
});

const deleteContact = (state, values, dispatch) => new Promise((resolve, reject) => {
if (values.customer_contact_id && state.customer.activeCustomer) {
    let url = `/basedata/customer/${state.customer.activeCustomer}/contact/${values.customer_contact_id}`
    httpRequest().delete(url)
        .then(r => {
            let url2 = `/basedata/customer/${state.customer.activeCustomer}/contact/`
            return httpRequest().get(url2);
        })
        .then(r => {
            let data = {};
            for (let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].customer_contact_id]: r.data[key]
                }
            }

            dispatch(customerContactLoadingSuccess(data));
            resolve('ok');
        })
        .catch(e => {
            reject(e);
        })
} else reject('customer_id missing');
});



export const contactModalHandler = (type, action, values, closeModalCallback) => {
    let state = store.getState();
    let func;
    if      (type === CONTACT_MODAL_TYPE.CONTACT && action === CONTACT_MODAL_ACTION.CREATE) func = createContact;
    else if (type === CONTACT_MODAL_TYPE.CONTACT && action === CONTACT_MODAL_ACTION.UPDATE) func = editContact;
    else if (type === CONTACT_MODAL_TYPE.CONTACT && action === CONTACT_MODAL_ACTION.DELETE) func = deleteContact;
    else func = () => new Promise((resolve) => { resolve(true) });

    return dispatch => {
        dispatch(modalStart());
        func(state, values, dispatch)
            .then(r => {
                    dispatch(modalSuccess());
                    closeModalCallback();
            })
            .catch(e => {
                dispatch(modalFail(e));
                closeModalCallback()
                const message = axiosErrorHandler(e, "")
                dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
            });
    }
}
