/**
 * UserData.js
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from "react";
import "./UserData.css";
import {connect } from 'react-redux';
import * as actions from './../../store/index';
import ModalUser, { USER_MODAL_TYPE, USER_MODAL_ACTION } from './ModalUser';
import { returnUserPictureURI } from './../../utils/httpInstance';

const UserCard = (props) => {
    console.log(props);
    return(
        <div className="user_card">
            <div className="user_card_top">
                <div className="user_card_pic">
                    <img className="userpic_basic" src={returnUserPictureURI(props.picture)} alt="" />
                </div>
                <div className="user_card_info">
                    <div className="user_card_info_row_headline">{props.givenname}&nbsp;{props.surmame}</div>
                    <div className="user_card_info_row">{props.mail}</div>
                    <div className="user_card_info_row">{props.role}</div>
                </div>
            </div>
            <div className="user_card_bottom">
                <div className="user_card_function">
                { props.roleId !== 1 || props.cardIsCurrentUser ?
                    (<>
                    <div className="basic_btn_icon" onClick={props.editClick}>
                        <i className="material-icons">edit</i>
                    </div>
                    <div className="basic_btn_icon" onClick={props.passwordReset}>
                        <i className="material-icons">no_encryption</i>
                    </div>
                    </>
                    ): null}
                </div>
            </div>
        </div>
    );
}



class UserData extends React.Component {
    state = {
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null,
    }
    openModal = (type, action, content) => {
        this.setState({
            showModal: true,
            modalType: type,
            modalAction: action,
            modalContent: content
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null
        })
    }
    componentDidMount() {
        document.title = "KB SOFT - Benutzer";
        if (!this.props.userLoading) this.props.loadUser();
    }
    
    render() {
        let userArray = [];
        let userOutput = null;
        if(this.props.user) {
            for (let key in this.props.user) {
                userArray.push({
                    key: "USER_" + key,
                    ...this.props.user[key]
                })
            }
        }

        if(!this.props.userLoading && userArray.length > 0) {
            console.log(userArray);
            userOutput = userArray.map(el => (
                <UserCard 
                    key={el.key}
                    givenname={el.user_given_name}
                    surmame={el.user_sur_name}
                    roleId={el.user_role_id}
                    cardIsCurrentUser={this.props.currentUserId === el.user_id}
                    mail={el.user_mail}
                    picture = {el.user_picture_file_name}
                    editClick={() => this.openModal(USER_MODAL_TYPE.USER, USER_MODAL_ACTION.UPDATE, el)}
                    passwordReset={() => this.openModal(USER_MODAL_TYPE.PASSWORD, USER_MODAL_ACTION.UPDATE, el)}
                />
            ))
        }

        return (
            <>
            <ModalUser 
                show={this.state.showModal}
                loading={this.props.modalLoading}
                close={() => this.closeModal()}
                action={this.state.modalAction}
                type={this.state.modalType}
				content={{...this.state.modalContent}}
				currentUserId={this.props.currentUserId}
                modalHandler={this.props.handleUserModal}
                inProcessing={false}
                applicationTitleOptions={this.props.applicationTitleOptions}
            />

                <div className="userdata_content_area scrollvisible">
                    <div className="userdata_user_cards">
                        <div className="user_card" onClick={() => this.openModal(USER_MODAL_TYPE.USER, USER_MODAL_ACTION.CREATE, {})}>
                            <div className="user_card_add">
                                <div className="basic_btn_icon">
                                    <i className="material-icons">add</i>
                                </div>
                            </div>
                        </div>
                        {userOutput}
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
		currentUserId: state.auth.user_id,
        user: state.user.user,
        userLoading: state.user.userLoading,
        userError: state.user.userError,
        applicationTitleOptions: state.application.applicationTitleOptions,
        modalLoading: state.user.modalLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => dispatch(actions.loadUser()),
		handleUserModal: (type, action, values, callback, file) => dispatch(actions.handleUserModal(type, action, values, callback, file))
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(UserData);
