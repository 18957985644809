import React, {useState, useEffect, useRef} from 'react';
import {
    ReviewGroupStripe
} from './ReviewGroupsStripe';
import {
    GROUPS_COPY_TYPE,
    NoElementsFound
} from './ReviewGroups';
import SimpleLoader from './../../../components/Loader/SimpleLoader';
import { GROUPS_MODAL_TYPE, GROUPS_MODAL_ACTION } from './ModalReviewGroups';

const TestGroupIDSonstiges = 1
const TestQuestionIDSonstiges = 22


const elementMapper = (type, el) => {
    let out = {};
    switch (type) {
        case GROUPS_COPY_TYPE.GROUP:
            out.id = el.test_group_id;
            out.name = el.test_group_name;
            out.orderId = el.test_group_order_id;
            out.editable = el.test_group_id !== TestGroupIDSonstiges;
            out.copyable = el.test_group_id !== TestGroupIDSonstiges;
            out.modalType = GROUPS_MODAL_TYPE.TEST_GROUP;
            out.copyType = GROUPS_COPY_TYPE.GROUP;
            out.copyParams = {
                test_group_id: el.test_group_id
            }
            return out;
        case GROUPS_COPY_TYPE.QUESTION:
            out.id = el.test_question_id;
            out.name = el.test_question_name;
            out.orderId = el.test_question_order_id;
            out.editable = el.test_question_id !== TestQuestionIDSonstiges;
            out.copyable = el.test_question_id !== TestQuestionIDSonstiges;
            out.modalType = GROUPS_MODAL_TYPE.TEST_QUESTION;
            out.copyType = GROUPS_COPY_TYPE.QUESTION;
            out.copyParams = {
                test_question_id: el.test_question_id
            }
            return out;
        case GROUPS_COPY_TYPE.FAULT:
            out.id = el.test_fault_id;
            out.name = el.test_fault_name;
            out.orderId = el.test_fault_order_id;
            out.editable = true
            out.copyable = true
            out.modalType = GROUPS_MODAL_TYPE.TEST_FAULT;
            out.copyType = GROUPS_COPY_TYPE.FAULT;
            out.copyParams = {
                test_fault_id: el.test_fault_id
            }
            return out;
        case GROUPS_COPY_TYPE.ACTION:
            out.id = el.test_fault_action_id;
            out.name = el.test_fault_action_name;
            out.orderId = el.test_fault_action_order_id;
            out.editable = true
            out.copyable = true
            out.modalType = GROUPS_MODAL_TYPE.TEST_ACTION;
            out.copyType = GROUPS_COPY_TYPE.ACTION;
            out.copyParams = {
                test_fault_action_id: el.test_fault_action_id
            }
            return out;
        default:
            return;
    }
}

const useRefState = initialValue => {
    const [state, setState] = useState(initialValue);
    const stateRef = useRef()
    useEffect(() => {
        stateRef.current = state
    }, [state])
    return [state, stateRef, setState]
}

/**
 * 
 * @param {String} props.containerName Container Name
 * @param {Any} props.enableAddFunction
 * @param {Function} props.addFunction Function on Add Click
 * @param {String} props.children Output for Childrens
 */
const ReviewGroupsWrapper = (props) => {
    console.log("RGW-Render")
    const [list, listRef, setList] = useRefState(props.childrenArray);
    // const updateList = (listVal) => useCallback(() => setList(listVal), [list]);
    const [dragging, setDragging] = useState(false);
    const dragItem = useRef();
    const dragNode = useRef();
    // useEffect with props.childrenArray as dependency!
    useEffect(() => {
        setList(props.childrenArray);
    }, [props.childrenArray, setList]);

    const handleDragStart = (e, params) => {
        setDragging(true);
        dragItem.current = params;
        dragNode.current = e.target;
        dragNode.current.addEventListener("dragend", handleDragEnd);
    }

    const handleDragEnter = (e, params) => {
        const currentItem = dragItem.current;
        if (!currentItem) return;
        // abort when node is null
        if (dragNode === null) return;
        // this is when group triggers enter
        if (isNaN(params.itemIndex)) return;
        // 
        if (params.itemIndex < 0 || currentItem.itemIndex < 0) return;

        if (e.target !== dragNode.current) {
            setList((old) => {
                let newList = [...old];
                newList.splice(
                    params.itemIndex,
                    0,
                    newList.splice(currentItem.itemIndex, 1)[0]
                    )
                    dragItem.current = params;
                    return newList;
                })
        }
            
    }

    const handleDragEnd = () => {
        dragNode.current.removeEventListener("dragend", handleDragEnd);
        dragItem.current = null;
        dragNode.current = null;
        let persistance = [];
        setTimeout(() => {
            let theList = listRef.current;
            theList.forEach((el, i) => {
                let id = "";
                switch(props.childrenType) {
                    case GROUPS_COPY_TYPE.GROUP: id = el.test_group_id;
                    break;
                    case GROUPS_COPY_TYPE.QUESTION: id = el.test_question_id;
                    break;
                    case GROUPS_COPY_TYPE.FAULT: id = el.test_fault_id;
                    break;
                    case GROUPS_COPY_TYPE.ACTION: id = el.test_fault_action_id;
                    break;
                    default: 
                    return;
                }
                persistance.push({id, orderId: i+1});
            })
            props.persistDragOrder(props.childrenType, persistance)
        }, 500)
        setDragging(false);
    }

    const getDragStyleing = (grp, index) => {
        const currentItem = dragItem.current;
        if (currentItem.itemIndex === index && currentItem.group === grp) return "reviewgroups_group_navpoint drag_active";
        return "reviewgroups_group_navpoint "
    };
    
    let childrenOutput = null;
    if (!props.childrenLoading && props.childrenArray.length > 0) {
        childrenOutput = list.map((el, itemIndex) => {
            const ele = elementMapper(props.childrenType, el);
            return (
                <ReviewGroupStripe 
                    key={el.key}
                    index={itemIndex}
                    id={ele.id}
                    name={ele.name}
                    type={ele.copyType}
                    orderId={ele.orderId}
                    active={ele.id === props.childrenActiveProp}
                    click={() => props.childrenClick(ele.id)}
                    editClick = {
                        ele.editable ? 
                            () => props.childrenEditClick(ele.modalType, GROUPS_MODAL_ACTION.UPDATE, el) : void(0)
                    }
                    copyClick={ele.copyable ? 
                            () => props.childrenCopyClick(ele.copyType, ele.copyParams) : void(0)}
                    onDragStartFunction={(e) => handleDragStart(e, {group: ele.copyType, itemIndex})}
                    onDragEnterFunction={(e) => handleDragEnter(e, {group: ele.copyType, itemIndex})}
                    additionalDragClass = {
                        () => dragging ? getDragStyleing(ele.copyType, itemIndex) : " reviewgroups_group_navpoint "
                    }
                />
            )
    })
    } else if (!props.childrenLoading && props.childrenArray.length === 0 && props.childrenActiveProp) {
        childrenOutput = <NoElementsFound name={"Keine " + props.containerName + " gefunden."}/>
    } else if (props.childrenLoading) {
        childrenOutput = <SimpleLoader />;
    }

    return (
        <div className="reviewgroups_groups_stripe">
            <div className="reviewgroups_groups_function">
                <div className="reviewgroups_stripe_name">
                    {props.containerName}
                </div>
                <div className="reviewgroups_function_btn">
                    {props.enableAddFunction  ? (<div className="basic_btn_icon" onClick={() => props.addFunction()}>
                        <i className="material-icons">
                            add
                        </i>
                    </div>):null}
                </div>
            </div>
            <div className="reviewgroups_groups_list scrollvisible">
                <div className={"reviewgroups_groups_listitems"}
                    onDragEnter={(e) => handleDragEnter(e, {group: props.childrenType, itemIndex: -1})}
                >
                    {childrenOutput}
                </div>
            </div>
        </div>
    );
}

export default ReviewGroupsWrapper;