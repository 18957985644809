/**
 * Loader.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from 'react';
import './Loader.css';

/**
 * KB SOFT Loader component
 * @param {String} props.headline // Additional Text, if nothing is provided it will display "Inhalt wird geladen..."
 * @param {String} props.subline // Additional Text, if nothing is provided it will display "Inhalt wird geladen..."
 * @returns KB-Brand Loader Component
 * @author Tim.Flauger
 */
const Loader = (props) => {
	let headline = null;
	if(props.headline) headline = <div className="spinner_headline" data-test="loader-headline">{props.headline}</div>;

	let subline = "Inhalt wird geladen...";
	if(props.subline) subline = props.subline;

	
    return (
    <div className="spinner_parent" data-test="loader">
        <div className="spinner">KB</div>
		{headline}
        <div className="spinner_subline">{subline}</div>
    </div>);
}


export default Loader;