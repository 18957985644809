/**
 * Contact.js
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import React from 'react';
import './Contacts.css';
import SimpleLoader from './../../../components/Loader/SimpleLoader';
import {connect} from 'react-redux';
import * as actions from './../../../store/index';
import ModalContacts, {CONTACT_MODAL_TYPE, CONTACT_MODAL_ACTION} from './ModalContacts';

const CustomerStripe = (props) => (
    <div className={"contact_navpoint " + (props.active ? "active_item": "")}>
        <div className="contact_listitem_text" onClick={props.click}>
            {props.name}
        </div>
    </div>
);


const CustomerNoElements = (props) => (
    <div className="contact_no_elements">
        <div className="contact_listitem_text">
            Keine Kunden gefunden.
        </div>
    </div>
);

const ContactCard = (props) => (
    <div className="contact_contact_singlecard">
        <div className="contact_singlecard_top">
            <div className="contact_contact_information">
                <div className="contact_information_name">
                    {props.surname}, {props.givenname}
                </div>
                <div className="contact_information_branch">
                    {props.branchname}
                </div>
                <div className="contact_information_row">
                    <div className="contact_information_icon">
                        <i className="material-icons">
                            turned_in
                        </i>
                    </div>
                    <div  className="contact_information_text">
                       {props.position}
                    </div>
                </div>
                <div className="contact_information_row">
                    <div className="contact_information_icon">
                        <i className="material-icons">
                            email
                        </i>
                    </div>
                    <div  className="contact_information_text">
                        {props.mail}
                    </div>
                </div>
                <div className="contact_information_row">
                    <div className="contact_information_icon">
                        <i className="material-icons">
                            phone
                        </i>
                    </div>
                    <div  className="contact_information_text">
                       {props.phone}
                    </div>
                </div>
            </div>
        </div>
        <div className="contact_singlecard_bottom">
            <div className="contact_singlecard_function" >
                <div className="basic_btn_icon" onClick={props.editClick}>
                    <i className="material-icons">
                        edit
                    </i>
                </div>
            </div>
        </div>
    </div>
);

const DEFAULT_SELECT_OPTIONS = {
    ALL: "ALL",
}

const DEFAULT_FILTER_OPTIONS = [
    {
        id: DEFAULT_SELECT_OPTIONS.ALL,
        name: "Alle Standorte"
    }
];

class Contacts extends React.Component {
    state = {
        showModal: false,
        modalType: null,
        modalAction: null,
        modalContent: null,
        activeFilter: DEFAULT_SELECT_OPTIONS.ALL
    }

    
    componentDidMount() {
        if(!this.props.customerLoading && !this.props.activeCustomer) this.props.loadCustomers();
        document.title = "KB SOFT - Kontakte";
        if (!this.props.customerLoading && !this.props.customerContactLoading && this.props.activeCustomer) this.props.loadContacts(this.props.activeCustomer);
    }

    openModal = (type, action, content) => {
        this.setState({
            showModal: true,
            modalType: type,
            modalAction: action,
            modalContent: content
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null
        })
    }

    handleBranchSorting = (event) => {
        this.setState({
            activeFilter: event.target.value
        });
    }

    render () {
        let customerArray = [];
        let customerOutput = null;
        let contactArray = [];
        let contactOutput = null;
        let createNewContactCard = null;
        let branchSelectFilterOptions = [...DEFAULT_FILTER_OPTIONS];
        let branchSelectFilterOptionsOutput = null;
        let branchSelectOptions = [];
        
        if(this.props.customer) {
            for(let key in this.props.customer) {
                customerArray.push({
                    key: "CUSTOMER_" + key,
                    ...this.props.customer[key]
                })
            }
        }

        if(!this.props.customerLoading && customerArray.length > 0) {
            customerOutput = customerArray.map( el => (
                <CustomerStripe 
                    key={el.key}
                    id={el.customer_id}
                    name={el.customer_name}
                    active={el.customer_id === this.props.activeCustomer}
                    click={() => this.props.loadContacts(el.customer_id)}
                />
            ))
        } else if (!this.props.customerLoading && customerArray.length === 0) {
            customerOutput = <CustomerNoElements />;
        } else customerOutput = <SimpleLoader />;

        if(this.props.customerContact) {
            for (let key in this.props.customerContact) {
                contactArray.push({
                    key: "CONTACT_" + key,
                    ...this.props.customerContact[key]
                })
            }
        }
        
        if (!this.props.customerContactLoading && !this.props.customerBranchLoading && contactArray.length > 0) {
            let filteredContacts = contactArray;
            if(this.state.activeFilter !== DEFAULT_SELECT_OPTIONS.ALL) {
                filteredContacts = contactArray.filter(el => el.customer_branch_id === this.state.activeFilter);
            }
            contactOutput = filteredContacts.map(el => (
                <ContactCard 
                    key={el.key}
                    surname={el.customer_contact_sur_name}
                    givenname={el.customer_contact_given_name}
                    branchname={el.customer_branch_name}
                    position = {el.customer_contact_position}
                    mail={el.customer_contact_mail}
                    phone={el.customer_contact_landline ? el.customer_contact_landline : el.customer_contact_cellphone_work}
                    editClick={() => this.openModal(CONTACT_MODAL_TYPE.CONTACT, CONTACT_MODAL_ACTION.UPDATE, el)}
                />
            ))
        } else if(this.props.customerContactLoading) contactOutput = <SimpleLoader />;
        if (!this.props.customerContactLoading && this.props.customerContact && this.props.activeCustomer !== null) {
            createNewContactCard = (
                <div className="contact_contact_singlecard" onClick={() => this.openModal(CONTACT_MODAL_TYPE.CONTACT, CONTACT_MODAL_ACTION.CREATE, {})}>
                    <div className="contact_singlecard_add">
                        <div className="basic_btn_icon">
                            <i className="material-icons">
                                add
                            </i>
                        </div>
                    </div>
                </div>
            )
        }

        if(!this.props.customerBranchLoading && this.props.customerBranch) {
            for(let key in this.props.customerBranch) {
                branchSelectOptions.push({
                    id: this.props.customerBranch[key].customer_branch_id,
                    name: this.props.customerBranch[key].customer_branch_name
                })
            }
        }

        branchSelectFilterOptionsOutput = branchSelectFilterOptions.concat(branchSelectOptions).map(el => (
            <option key={el.id} value={el.id}>{el.name}</option>
        ))

        return(
            <React.Fragment>
                <ModalContacts 
                    show={this.state.showModal}
                    loading={this.props.modalLoading}
                    close={() => this.closeModal()}
                    action={this.state.modalAction}
                    type={this.state.modalType}
                    content={{...this.state.modalContent}}
                    modalHandler={this.props.contactModalHandler}
                    inProcessing={false}
                    applicationTitleOptions={this.props.applicationTitleOptions}
                    selectBranchOptions={branchSelectOptions}
                />
           <div className="contact_content_area">
               <div className="contact_content_sidebar">
                <div className="contact_list_function">
                    <div className="contact_sidebar_headline">
                        Kunden
                    </div>
                </div>
                <div className="contact_list scrollvisible">
                    <div className="contact_listitems">
                        {customerOutput}
                    </div>
                </div>
               </div>
               <div className="contact_content_view">
                <div className="contact_content_subnav">
                    <div className="contact_branch_text">
                        Standort:
                    </div>
                    <div className="contact_branch_select">
                        <select 
                            className="select" 
                            value={this.state.activeFilter}
                            onChange={(evt)=> this.handleBranchSorting(evt)}
                            >
                            {branchSelectFilterOptionsOutput}
                        </select>
                    </div>
                   
                </div>
                <div className="contact_contact_area scrollvisible">
                    <div className="contact_contact_cards">
                        {createNewContactCard}
                        {contactOutput}
                    </div>
                </div>
               </div>
           </div>
           </ React.Fragment>
        );
    }
} 

const mapStateToProps = state => {
    return {
        activeCustomer: state.customer.activeCustomer,
        customerLoading: state.customer.customerLoading,
        customer: state.customer.customer,
        customerBranch: state.customer.customerBranch,
        customerBranchLoading: state.customer.customerBranchLoading,
        customerContact: state.customer.customerContact,
        customerContactLoading: state.customer.customerContactLoading,
        modalLoading: state.customer.modalLoading,
        applicationTitleOptions: state.application.applicationTitleOptions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCustomers: () => dispatch(actions.loadCustomers()),
        loadContacts: (customer_id) => dispatch(actions.loadContacts(customer_id)),
        loadCustomerBranches: (customer_id) => dispatch(actions.loadCustomerBranches(customer_id)),
        contactModalHandler: (type, action, values, callback) => dispatch(actions.contactModalHandler(type, action, values, callback))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
