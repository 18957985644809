/**
 * actionTypes.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

// Authentication Types
export const AUTH_START             = "AUTH_START";
export const AUTH_SUCCESS           = "AUTH_SUCCESS";
export const AUTH_FAIL              = "AUTH_FAIL";
export const AUTH_LOGOUT            = "AUTH_LOGOUT";
export const AUTH_CHANGE_USERDATA   = "AUTH_CHANGE_USERDATA";

// Application Types
export const APP_NOTIFY_ADD = "APP_NOTIFY_ADD";
export const APP_NOTIFY_DISMISS = "APP_NOTIFY_DISMISS";
export const APP_NOTIFY_CLEARALL = "APP_NOTIFY_CLEARALL";


// ReviewGroups

export const REVIEWGROUP_SET_ACTIVE_PROCESS                 = "REVIEWGROUP_SET_ACTIVE_PROCESS";
export const REVIEWGROUP_SET_ACTIVE_TESTGROUP               = "REVIEWGROUP_SET_ACTIVE_TESTGROUP";
export const REVIEWGROUP_SET_ACTIVE_TESTQUESTION            = "REVIEWGROUP_SET_ACTIVE_TESTQUESTION";
export const REVIEWGROUP_SET_ACTIVE_TESTFAULT               = "REVIEWGROUP_SET_ACTIVE_TESTFAULT";

export const REVIEWGROUP_REORDER_TESTGROUP                  = "REVIEWGROUP_REORDER_TESTGROUP";
export const REVIEWGROUP_REORDER_TESTQUESTION               = "REVIEWGROUP_REORDER_TESTQUESTION";
export const REVIEWGROUP_REORDER_TESTFAULT                  = "REVIEWGROUP_REORDER_TESTFAULT";
export const REVIEWGROUP_REORDER_TESTFAULTACTION            = "REVIEWGROUP_REORDER_TESTFAULTACTION";

export const REVIEWGROUP_REORDER_TESTGROUP_SAVE             = "REVIEWGROUP_REORDER_TESTGROUP_SAVE";
export const REVIEWGROUP_REORDER_TESTQUESTION_SAVE          = "REVIEWGROUP_REORDER_TESTQUESTION_SAVE";
export const REVIEWGROUP_REORDER_TESTFAULT_SAVE             = "REVIEWGROUP_REORDER_TESTFAULT_SAVE";
export const REVIEWGROUP_REORDER_TESTFAULTACTION_SAVE       = "REVIEWGROUP_REORDER_TESTFAULTACTION_SAVE";

export const REVIEWGROUP_TESTGROUP_LOADING_START            = "REVIEWGROUP_TESTGROUP_LOADING_START";
export const REVIEWGROUP_TESTGROUP_LOADING_SUCCESS          = "REVIEWGROUP_TESTGROUP_LOADING_SUCCESS";
export const REVIEWGROUP_TESTGROUP_LOADING_FAIL             = "REVIEWGROUP_TESTGROUP_LOADING_FAIL";

export const REVIEWGROUP_TESTQUESTION_LOADING_START         = "REVIEWGROUP_TESTQUESTION_LOADING_START";
export const REVIEWGROUP_TESTQUESTION_LOADING_SUCCESS       = "REVIEWGROUP_TESTQUESTION_LOADING_SUCCESS";
export const REVIEWGROUP_TESTQUESTION_LOADING_FAIL          = "REVIEWGROUP_TESTQUESTION_LOADING_FAIL";

export const REVIEWGROUP_TESTFAULT_LOADING_START            = "REVIEWGROUP_TESTFAULT_LOADING_START";
export const REVIEWGROUP_TESTFAULT_LOADING_SUCCESS          = "REVIEWGROUP_TESTFAULT_LOADING_SUCCESS";
export const REVIEWGROUP_TESTFAULT_LOADING_FAIL             = "REVIEWGROUP_TESTFAULT_LOADING_FAIL";

export const REVIEWGROUP_TESTFAULTACTION_LOADING_START      = "REVIEWGROUP_TESTFAULTACTION_LOADING_START";
export const REVIEWGROUP_TESTFAULTACTION_LOADING_SUCCESS    = "REVIEWGROUP_TESTFAULTACTION_LOADING_SUCCESS";
export const REVIEWGROUP_TESTFAULTACTION_LOADING_FAIL       = "REVIEWGROUP_TESTFAULTACTION_LOADING_FAIL";

export const REVIEWGROUP_MODAL_START                        = "REVIEWGROUP_MODAL_START";
export const REVIEWGROUP_MODAL_SUCCESS                      = "REVIEWGROUP_MODAL_SUCCESS";
export const REVIEWGROUP_MODAL_FAIL                         = "REVIEWGROUP_MODAL_FAIL";

export const REVIEWGROUP_COPY_START                        	= "REVIEWGROUP_COPY_START";
export const REVIEWGROUP_COPY_SUCCESS                      	= "REVIEWGROUP_COPY_SUCCESS";
export const REVIEWGROUP_COPY_FAIL                         	= "REVIEWGROUP_COPY_FAIL";

// Customer

export const CUSTOMER_SET_ACTIVE_CUSTOMER                   = "CUSTOMER_SET_ACTIVE_CUSTOMER";

export const CUSTOMER_CUSTOMER_LOADING_START                = "CUSTOMER_CUSTOMER_LOADING_START";
export const CUSTOMER_CUSTOMER_LOADING_SUCCESS              = "CUSTOMER_CUSTOMER_LOADING_SUCCESS";
export const CUSTOMER_CUSTOMER_LOADING_FAIL                 = "CUSTOMER_CUSTOMER_LOADING_FAIL";

export const CUSTOMER_CUSTOMER_BRANCH_LOADING_START         = "CUSTOMER_CUSTOMER_BRANCH_LOADING_START";
export const CUSTOMER_CUSTOMER_BRANCH_LOADING_SUCCESS       = "CUSTOMER_CUSTOMER_BRANCH_LOADING_SUCCESS";
export const CUSTOMER_CUSTOMER_BRANCH_LOADING_FAIL          = "CUSTOMER_CUSTOMER_BRANCH_LOADING_FAIL";

export const CUSTOMER_CUSTOMER_CONTACT_LOADING_START        = "CUSTOMER_CUSTOMER_CONTACT_LOADING_START";
export const CUSTOMER_CUSTOMER_CONTACT_LOADING_SUCCESS      = "CUSTOMER_CUSTOMER_CONTACT_LOADING_SUCCESS";
export const CUSTOMER_CUSTOMER_CONTACT_LOADING_FAIL         = "CUSTOMER_CUSTOMER_CONTACT_LOADING_FAIL";

export const CUSTOMER_MODAL_START                            = "CUSTOMER_MODAL_START";
export const CUSTOMER_MODAL_SUCCESS                          = "CUSTOMER_MODAL_SUCCESS";
export const CUSTOMER_MODAL_FAIL                             = "CUSTOMER_MODAL_FAIL";

// User

export const USER_LOADING_START                             = "USER_LOADING_START";
export const USER_LOADING_SUCCESS                           = "USER_LOADING_SUCCESS";
export const USER_LOADING_FAIL                              = "USER_LOADING_FAIL";

export const USER_MODAL_START                               = "USER_MODAL_START";
export const USER_MODAL_SUCCESS                             = "USER_MODAL_SUCCESS";
export const USER_MODAL_FAIL                                = "USER_MODAL_FAIL";
