/**
 * application.reducer.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

// import * as actionTypes from './../actionTypes';
// import { _updateObject } from './../../utils/helper';

const initialState = {
   applicationTitle: {
       1: { application_title_id: 1,
            application_title_name: "Keine Angabe",
            application_title_salutation: "Hallo"
       },
       2: {
            application_title_id: 2,
            application_title_name: "Herr",
            application_title_salutation: "Sehr geehrter Herr"
       },
       3: {
            application_title_id: 3,
            application_title_name: "Frau",
            application_title_salutation: "Sehr geehrte Frau"
       }
   },
   applicationTitleOptions: {
        1: { id: 1,
            name: "Keine Angabe",
       },
       2: {
            id: 2,
            name: "Herr",
       },
       3: {
            id: 3,
            name: "Frau",
       }
   }
};

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default applicationReducer;
