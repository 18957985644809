/**
 * auth.actions.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import * as actionTypes from './../actionTypes';
import axios from 'axios';
import { notificationProvider, NOTIFICATION_TYPES } from './notification.actions';
import { axiosErrorHandler } from '../../utils/axiosErrorHandler';
const REFRESHTOKEN = "X-RT";
let ENDPOINT = window._KB_ENDPOINT;

const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

const authSuccess = (data) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        data: data
    }
}

const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL
    }
}

const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const changeUserData = (userObject) => {
    return {
        type: actionTypes.AUTH_CHANGE_USERDATA,
        userObject: userObject
    }
}

const storeLocalStorage = (refreshToken) => {
    localStorage.setItem(REFRESHTOKEN, refreshToken);
}

const clearLocalStorage = () => {
    localStorage.removeItem(REFRESHTOKEN);
}



export const auth = (mail, password) => {

    return dispatch => {
        // dispatch(notificationProvider.clear());
        dispatch(authStart());
        axios.post(ENDPOINT + "/api/auth/web", {
            mail: mail,
            password: password
        })
        .then(res => {
            dispatch(authSuccess(res.data))

            storeLocalStorage(res.data.refresh);
        })
        .catch(err => {
            dispatch(authFail(err))
            clearLocalStorage();

            let message = axiosErrorHandler(err, "Login ungültig");
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.ERROR, message));
        })
    }
}


export const reAuth = () => {
    let refreshToken = localStorage.getItem(REFRESHTOKEN);
    return dispatch => {
        if(!refreshToken) dispatch(authFail());
        else {
            dispatch(notificationProvider.clear());
            dispatch(authStart());
            axios({
                url: ENDPOINT + "/api/auth/web/refresh",
                method: "post",
                headers: {'X-RT': refreshToken}
            })
            .then(res => {
                dispatch(authSuccess(res.data));
                storeLocalStorage(res.data.refresh)
            })
            .catch(err => {
                dispatch(authFail(err));
                clearLocalStorage();
                let message = axiosErrorHandler(err, "Login ungültig");
                dispatch(notificationProvider.add(NOTIFICATION_TYPES.ERROR, message));
            })
        }
    }
}


export const handleLogout = () => {
    return dispatch => {
        clearLocalStorage();
        dispatch(authLogout());
        dispatch(notificationProvider.add(NOTIFICATION_TYPES.INFO, "Logout erfolgreich"));
    }
}
