/**
 * Input.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import React from "react";


// switch Input cases through props.elementType
// propertys are passed in the following order:
// controls: {
//         **ELEMENTNAME***: {
//                      elementType: 'input',
//                      elementConfig: {
//                          type: 'text',
//                          placeholder: 'My Placeholder'
//                          // for textarea something like rows, and cols here
//                      },
//                      value: '',
//                      touched: false,
//                      css: 'testclass'
//         },

/**-----------------------------------------------------------
 * Functional Input Component
 * @returns {Component}
 * @author Tim.Flauger
 */
const input = props => {
    let inputElement = null;
    switch (props.elementType) {
        // Label Component 
        // Displays a Label with the possibilites of prefix and suffix.
        case "labelAsText":
            inputElement = (
                <div className={props.css}>
                    {props.elementConfig.labelPrefix}
                    <span>{props.label}</span>
                    {props.elementConfig.labelSuffix}
                </div>
            );
            break;
        // Default Input Component
        case "input":
            inputElement = (
                <input className={props.css} {...props.elementConfig} value={props.value} onChange={props.changed} disabled={props.disabled} />
            );
            break;
        // Default Input Component but forced to be hidden
        case "hiddenInput":
            inputElement = (
                <input type="hidden" value={props.value} onChange={props.changed} />
            );
            break;
        // InputElement with Label
        case "inputWithLabel":
            inputElement = (
                <div className={props.css}>
                    <label>{props.label}</label>
                    <input 
                        {...props.elementConfig} 
                        value={props.value} 
                        onChange={props.changed} 
                        disabled={props.disabled} 
                    />
                </div>
            );
            break;
        // Checkbox Element
        case "checkbox":
            inputElement = (
                <input 
                    className={props.css} 
                    {...props.elementConfig} 
                    checked={props.value} 
                    onChange={props.changed} 
                    disabled={props.disabled} 
                />
            );
            break;
        // Checkbox With Label
        case "checkWithLabel":
            inputElement = (
                <div className={props.css}>
                    <label>{props.label}</label>
                    <span>
                        <input 
                            {...props.elementConfig} 
                            checked={props.value} 
                            onChange={props.changed} 
                            disabled={props.disabled} 
                        />
                        {props.elementConfig.placeholder}
                    </span>
                </div>
            );
            break;
        // Textarea Component
        case "textarea":
            inputElement = (
                <textarea 
                    className={props.css} 
                    {...props.elementConfig} 
                    value={props.value} 
                    onChange={props.changed} 
                    disabled={props.disabled}
                >
                </textarea>
            );
            break;
        // Textarea with Label
        case "textareaWithLabel":
            inputElement = (
                <div className={props.css}>
                    <label>{props.label}</label>
                    <textarea 
                        {...props.elementConfig} 
                        value={props.value} 
                        onChange={props.changed} 
                        disabled={props.disabled}
                    >
                    </textarea>
                </div>
            );
            break;
        // Select with Label 
        // Expects defaultValue, and defaultOptionDisabled prop
        // on elementConfig.options an Array is expected to loop through options and values.
        // options need to have a id and name prop.
        case "selectWithLabel":
            inputElement = (
                <div className={props.css}>
                    <label>{props.label}</label>
                    <select value={props.value} onChange={props.changed} disabled={props.disabled}>
                        <option value={props.elementConfig.defaultValue} disabled={props.elementConfig.defaultOptionDisabled}>
                            {props.elementConfig.defaultOptionText || "Bitte Wählen..."}
                        </option>
                        {Object.keys(props.elementConfig.options).length !== 0 ? (
                            Object.keys(props.elementConfig.options).map(key => (
                                <option key={+props.elementConfig.options[key].id} value={props.elementConfig.options[key].id}>
                                    {props.elementConfig.options[key].name}
                                </option>
                            ))
                        ) : (
                            <option disabled={true}>...</option>
                        )}
                    </select>
                </div>
            );
            break;
        // select field
        case "select":
            inputElement = (
                <select className={props.css} value={props.value} onChange={props.changed} disabled={props.disabled}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            );
            break;

        default:
            inputElement = <input className={props.cssStyles} {...props.elementConfig} value={props.value} onChange={props.changed} disabled={props.disabled} />;
    }
    return inputElement;
};

export default input;
