/**
 * MasterData.js
 * kbsoft-webapp
 * 
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from "react";
import "./MasterData.css";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";

import ReviewGroups from "./ReviewGroups/ReviewGroups";
import Customers from "./Customers/Customers";
import Contacts from "./Contacts/Contacts";
import Default from "./Default/Default";
import ProcessSelection from './ProcessSelection/ProcessSelection';


class MasterData extends React.Component {
    render() {
        return (
            <div className="masterdata_content_area">
                <div className="masterdata_subnav_area">
                    <div className="masterdata_subnav_navpoint">
                        <NavLink to={this.props.match.url + "/process"}>Prüfgruppen</NavLink>
                        <NavLink to={this.props.match.url + "/customers"}>Kunden &amp; Standorte</NavLink>
                        <NavLink to={this.props.match.url + "/contacts"}>Kontakte</NavLink>
                    </div>
                </div>
                <div className="masterdata_subcontent_area">
                    <Switch>
                        <Route exact path={this.props.match.url + "/process"} component={ProcessSelection} />
                        <Route path={this.props.match.url + "/process/:processId/reviewgroups"} component={ReviewGroups} />
                        <Route path={this.props.match.url + "/customers"} component={Customers} />
                        <Route path={this.props.match.url + "/contacts"} component={Contacts} />
                        <Route path={this.props.match.url + "/"} component={Default} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(MasterData);
