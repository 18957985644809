/**
 * reviewgroup.actions.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import * as actionTypes from './../actionTypes';
import { notificationProvider, NOTIFICATION_TYPES } from './notification.actions';
import { axiosErrorHandler } from '../../utils/axiosErrorHandler';
import { httpRequest } from './../../utils/httpInstance';
import { store } from './../../index';
// import {
//     config
// } from './../../config';
import { GROUPS_MODAL_ACTION, GROUPS_MODAL_TYPE } from './../../container/MasterData/ReviewGroups/ModalReviewGroups';
import { GROUPS_COPY_TYPE } from './../../container/MasterData/ReviewGroups/ReviewGroups';
import { _editObjectChildByKey } from './../../utils/helper';


const setActiveProcess = (process_id) => {
    return {
        type: actionTypes.REVIEWGROUP_SET_ACTIVE_PROCESS,
        activeProcess: process_id
    }
}

const setActiveTestGroup = (test_group_id) => {
    return {
        type: actionTypes.REVIEWGROUP_SET_ACTIVE_TESTGROUP,
        activeTestGroup: test_group_id
    }
}

const setActiveTestQuestion = (test_question_id) => {
    return {
        type: actionTypes.REVIEWGROUP_SET_ACTIVE_TESTQUESTION,
        activeTestQuestion: test_question_id
    }
}

const setActiveTestFault = (test_fault_id) => {
    return {
        type: actionTypes.REVIEWGROUP_SET_ACTIVE_TESTFAULT,
        activeTestFault: test_fault_id
    }
}

const testGroupLoadingStart = () => {
    return {
        type: actionTypes.REVIEWGROUP_TESTGROUP_LOADING_START
    }
}

const testGroupLoadingSuccess = (data) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTGROUP_LOADING_SUCCESS,
        data: data
    }
}

const testGroupLoadingFail = (e) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTGROUP_LOADING_FAIL,
        error: e
    }
}

const testQuestionLoadingStart = () => {
    return {
        type: actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_START
    }
}

const testQuestionLoadingSuccess = (data) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_SUCCESS,
        data: data
    }
}

const testQuestionLoadingFail = (e) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTQUESTION_LOADING_FAIL,
        error: e
    }
}

const testFaultLoadingStart = () => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULT_LOADING_START
    }
}

const testFaultLoadingSuccess = (data) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULT_LOADING_SUCCESS,
        data: data
    }
}

const testFaultLoadingFail = (e) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULT_LOADING_FAIL,
        error: e
    }
}

const testFaultActionLoadingStart = () => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_START
    }
}

const testFaultActionLoadingSuccess = (data) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_SUCCESS,
        data: data
    }
}

const testFaultActionLoadingFail = (e) => {
    return {
        type: actionTypes.REVIEWGROUP_TESTFAULTACTION_LOADING_FAIL,
        error: e
    }
}

const modalStart = () => {
    return {
        type: actionTypes.REVIEWGROUP_MODAL_START
    }
}

const modalSuccess = () => {
    return {
        type: actionTypes.REVIEWGROUP_MODAL_SUCCESS
    }
}

const modalFail = (error) => {
    return {
        type: actionTypes.REVIEWGROUP_MODAL_FAIL,
        error: error
    }
}

const copyStart = () => {
	return {
		type: actionTypes.REVIEWGROUP_COPY_START
	}
}

const copySuccess = () => {
	return {
		type: actionTypes.REVIEWGROUP_COPY_SUCCESS
	}
}

const copyFail = (error) => {
	return {
		type: actionTypes.REVIEWGROUP_COPY_FAIL,
		error: error
	}
}

const updateOrderGroup = () => {
    return {
        type: actionTypes.REVIEWGROUP_REORDER_TESTGROUP,
    }
}
const updateOrderQuestion = () => {
    return {
        type: actionTypes.REVIEWGROUP_REORDER_TESTQUESTION,
    }
}
const updateOrderFault = () => {
    return {
        type: actionTypes.REVIEWGROUP_REORDER_TESTFAULT,
    }
}
const updateOrderAction = () => {
    return {
        type: actionTypes.REVIEWGROUP_REORDER_TESTFAULTACTION,
    }
}

export const loadGroups = (process_id) => {
    return dispatch => {
        dispatch(setActiveProcess(+process_id));
        dispatch(testGroupLoadingStart());
        httpRequest().get('/basedata/process/'+ process_id + '/group/')
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].test_group_id]: r.data[key]
                }
            }
            dispatch(testGroupLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(testGroupLoadingFail(e));
            const message = axiosErrorHandler(e, "Die Prüfgruppen konnten nicht geladen werden, bitte versuchen Sie es erneut.")
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
        })
    }
}

export const loadQuestions = (process_id, test_group_id) => {
    return dispatch => {
        dispatch(setActiveTestGroup(+test_group_id));
        dispatch(testQuestionLoadingStart());
        httpRequest().get('/basedata/process/' + process_id + "/group/" + test_group_id + "/question/")
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].test_question_id]: r.data[key]
                }
            }
            dispatch(testQuestionLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(testQuestionLoadingFail(e));
            const message = axiosErrorHandler(e, "Die Prüffragen konnten nicht geladen werden, bitte versuchen Sie es erneut.")
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
        })
    }
}

export const loadFaults = (process_id, test_group_id, test_question_id) => {
    return dispatch => {
        dispatch(setActiveTestQuestion(+test_question_id));
        dispatch(testFaultLoadingStart());
        httpRequest().get('/basedata/process/' + process_id + "/group/" + test_group_id + "/question/" + test_question_id + "/fault/")
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].test_fault_id]: r.data[key]
                }
            }
            dispatch(testFaultLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(testFaultLoadingFail(e));
            const message = axiosErrorHandler(e, "Die Fehler konnten nicht geladen werden, bitte versuchen Sie es erneut.")
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
        })
    }
}

export const loadFaultActions = (process_id, test_group_id, test_question_id, test_fault_id) => {
    return dispatch => {
        dispatch(setActiveTestFault(+test_fault_id));
        dispatch(testFaultActionLoadingStart());
        httpRequest().get(`/basedata/process/${+process_id}/group/${+test_group_id}/question/${+test_question_id}/fault/${+test_fault_id}/action/`)
        .then(r => {
            let data = {};
            for(let key in r.data) {
                data={
                    ...data,
                    [r.data[key].test_fault_action_id]: r.data[key]
                }
            }
            dispatch(testFaultActionLoadingSuccess(data));
        })
        .catch(e => {
            dispatch(testFaultActionLoadingFail(e));
            const message = axiosErrorHandler(e, "Die Maßnahmen konnten nicht geladen werden, bitte versuchen Sie es erneut.")
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
        })
    }
}


const createTestGroup = (state, values, dispatch) => new Promise((resolve, reject) => {
    if(state.reviewgroup.activeProcess) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/`;
        httpRequest().post(url, values)
        .then(r => {
            return httpRequest().get(url);
        })
        .then(r => {
            let data = {};
            for (let key in r.data) {
                data = {
                    ...data,
                    [r.data[key].test_group_id]: r.data[key]
                }
            }
            dispatch(testGroupLoadingSuccess(data));
            resolve('ok');
        })
        .catch(e => {
            reject(e);
        })

    } else reject("process_id missing")
});

const updateTestGroup = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.reviewgroup.activeProcess && values.test_group_id) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+values.test_group_id}`;
        httpRequest().patch(url, values)
        .then(r => {
            return httpRequest().get(url)
        })
        .then(r => {
            let groupObject = state.reviewgroup.testGroup;
            let newGroup = _editObjectChildByKey(groupObject, r.data.test_group_id, r.data);
            dispatch(testGroupLoadingSuccess(newGroup));
            resolve('ok');
        })
        .catch(e => {
            reject(e);
        })
    } else reject("process_id missing")
});

const deleteTestGroup = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.reviewgroup.activeProcess && values.test_group_id) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+values.test_group_id}`;
        httpRequest().delete(url)
        .then(r => {
                let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/`;
                return httpRequest().get(url)
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].test_group_id]: r.data[key]
                    }
                }
                dispatch(setActiveProcess(+state.reviewgroup.activeProcess));
                dispatch(testGroupLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id missing")
});

const createTestQuestion = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.reviewgroup.activeProcess && state.reviewgroup.activeTestGroup) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/`;
        httpRequest().post(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].test_question_id]: r.data[key]
                    }
                }
                dispatch(testQuestionLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group missing")
});

const updateTestQuestion = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.reviewgroup.activeProcess && state.reviewgroup.activeTestGroup && values.test_question_id) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/${+values.test_question_id}`;
        httpRequest().patch(url, values)
        .then(r => {
            return httpRequest().get(url)
        })
        .then(r => {
            let questionObj = state.reviewgroup.testQuestion;
            let newQuestion = _editObjectChildByKey(questionObj, r.data.test_question_id, r.data);
            dispatch(testQuestionLoadingSuccess(newQuestion));
            resolve('ok');
        })
        .catch(e => {
            reject(e);
        })
    } else reject("process_id or test_group missing")
});

const deleteTestQuestion = (state, values, dispatch) => new Promise((resolve, reject) => {
    if (state.reviewgroup.activeProcess && state.reviewgroup.activeTestGroup && values.test_question_id) {
        let url = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/${+values.test_question_id}`;
        httpRequest().delete(url)
        .then(r => {
                let url2 = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/`;
                return httpRequest().get(url2);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].test_question_id]: r.data[key]
                    }
                }
                dispatch(setActiveTestGroup(+state.reviewgroup.activeTestGroup));
                dispatch(testQuestionLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group missing")
});

const createTestFault = (state, values, dispatch) => new Promise((resolve, reject) => {
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;

    if (activeProcess && activeGroup && activeQuestion) {
        let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/`;
        httpRequest().post(url, values)
            .then(r => {
                return httpRequest().get(url);
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].test_fault_id]: r.data[key]
                    }
                }
                dispatch(testFaultLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group or test_question missing")
});

const updateTestFault = (state, values, dispatch) => new Promise((resolve, reject) => {
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;
    let faultId = values.test_fault_id;
    if (activeProcess && activeGroup && activeQuestion && faultId) {
        let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+faultId}`;
        httpRequest().patch(url, values)
            .then(r => {
                return httpRequest().get(url)
            })
            .then(r => {
                let faultObj = state.reviewgroup.testFault;
                let newFault = _editObjectChildByKey(faultObj, r.data.test_fault_id, r.data);
                dispatch(testFaultLoadingSuccess(newFault));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group or test_question missing")
});

const deleteTestFault = (state, values, dispatch) => new Promise((resolve, reject) => {
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;
    let faultId = values.test_fault_id;
    if (activeProcess && activeGroup && activeQuestion && faultId) {
        let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+faultId}`;
        httpRequest().delete(url)
            .then(r => {
                let url2 = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/`;
                return httpRequest().get(url2)
            })
            .then(r => {
                let data = {};
                for (let key in r.data) {
                    data = {
                        ...data,
                        [r.data[key].test_fault_id]: r.data[key]
                    }
                }
                dispatch(setActiveTestQuestion(+activeQuestion));
                dispatch(testFaultLoadingSuccess(data));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group or test_question missing")
});

const createTestAction = (state, values, dispatch) => new Promise((resolve, reject) => {
     let activeProcess = state.reviewgroup.activeProcess;
     let activeGroup = state.reviewgroup.activeTestGroup;
     let activeQuestion = state.reviewgroup.activeTestQuestion;
     let activeFault = state.reviewgroup.activeTestFault;

     if (activeProcess && activeGroup && activeQuestion && activeFault) {
         let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/`;
         httpRequest().post(url, values)
             .then(r => {
                 return httpRequest().get(url);
             })
             .then(r => {
                 let data = {};
                 for (let key in r.data) {
                     data = {
                         ...data,
                         [r.data[key].test_fault_action_id]: r.data[key]
                     }
                 }
                 dispatch(testFaultActionLoadingSuccess(data));
                 resolve('ok');
             })
             .catch(e => {
                 reject(e);
             })

    } else reject("process_id or test_group or test_question missing")
});

const updateTestAction = (state, values, dispatch) => new Promise((resolve, reject) => {
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;
    let activeFault = state.reviewgroup.activeTestFault;
    let actionId = values.test_fault_action_id;
    if (activeProcess && activeGroup && activeQuestion && activeFault && actionId) {
        let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/${+actionId}`;
        httpRequest().patch(url, values)
            .then(r => {
                return httpRequest().get(url)
            })
            .then(r => {
                let actionObj = state.reviewgroup.testFaultAction;
                let newActionObj = _editObjectChildByKey(actionObj, r.data.test_fault_action_id, r.data);
                dispatch(testFaultActionLoadingSuccess(newActionObj));
                resolve('ok');
            })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group or test_question missing")
});

const deleteTestAction = (state, values, dispatch) => new Promise((resolve, reject) => {
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;
    let activeFault = state.reviewgroup.activeTestFault;
    let actionId = values.test_fault_action_id;
    if (activeProcess && activeGroup && activeQuestion && activeFault && actionId) {
        let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/${+actionId}`;
        httpRequest().delete(url)
            .then(r => {
                let url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/`;
                return httpRequest().get(url)
            })
             .then(r => {
                 let data = {};
                 for (let key in r.data) {
                     data = {
                         ...data,
                         [r.data[key].test_fault_action_id]: r.data[key]
                     }
                 }
                 dispatch(testFaultActionLoadingSuccess(data));
                 resolve('ok');
             })
            .catch(e => {
                reject(e);
            })
    } else reject("process_id or test_group or test_question missing")
});


export const reviewGroupsModalHandler = (type, action, values, closeModalCallback) => {
    let state = store.getState();
    let func;
    // TEST GROUPS
    if (type === GROUPS_MODAL_TYPE.TEST_GROUP && action === GROUPS_MODAL_ACTION.CREATE) func = createTestGroup;
    else if (type === GROUPS_MODAL_TYPE.TEST_GROUP      && action === GROUPS_MODAL_ACTION.UPDATE) func = updateTestGroup;
    else if (type === GROUPS_MODAL_TYPE.TEST_GROUP      && action === GROUPS_MODAL_ACTION.DELETE) func = deleteTestGroup;
    else if (type === GROUPS_MODAL_TYPE.TEST_QUESTION   && action === GROUPS_MODAL_ACTION.CREATE) func = createTestQuestion;
    else if (type === GROUPS_MODAL_TYPE.TEST_QUESTION   && action === GROUPS_MODAL_ACTION.UPDATE) func = updateTestQuestion;
    else if (type === GROUPS_MODAL_TYPE.TEST_QUESTION   && action === GROUPS_MODAL_ACTION.DELETE) func = deleteTestQuestion;
    else if (type === GROUPS_MODAL_TYPE.TEST_FAULT      && action === GROUPS_MODAL_ACTION.CREATE) func = createTestFault;
    else if (type === GROUPS_MODAL_TYPE.TEST_FAULT      && action === GROUPS_MODAL_ACTION.UPDATE) func = updateTestFault;
    else if (type === GROUPS_MODAL_TYPE.TEST_FAULT      && action === GROUPS_MODAL_ACTION.DELETE) func = deleteTestFault;
    else if (type === GROUPS_MODAL_TYPE.TEST_ACTION     && action === GROUPS_MODAL_ACTION.CREATE) func = createTestAction;
    else if (type === GROUPS_MODAL_TYPE.TEST_ACTION     && action === GROUPS_MODAL_ACTION.UPDATE) func = updateTestAction;
    else if (type === GROUPS_MODAL_TYPE.TEST_ACTION     && action === GROUPS_MODAL_ACTION.DELETE) func = deleteTestAction;
    else func = () => new Promise((resolve) => {resolve(true)});

    let notificationType = ""
    if (type === GROUPS_MODAL_TYPE.TEST_GROUP) notificationType = "Die Prüfgruppe";
    else if (type === GROUPS_MODAL_TYPE.TEST_QUESTION) notificationType = "Die Prüffrage";
    else if (type === GROUPS_MODAL_TYPE.TEST_FAULT) notificationType = "Der Fehler";
    else if (type === GROUPS_MODAL_TYPE.TEST_ACTION) notificationType = "Die Maßnahme";

    let notificationAction = ""
    if (action === GROUPS_MODAL_ACTION.CREATE) notificationAction = "erstellt";
    else if (action === GROUPS_MODAL_ACTION.UPDATE) notificationAction = "bearbeitet";
    else if (action === GROUPS_MODAL_ACTION.DELETE) notificationAction = "gelöscht";

    return dispatch => {
        dispatch(modalStart());
        func(state, values, dispatch)
        .then(r => {
                dispatch(modalSuccess());
                closeModalCallback();
        })
        .catch(e => {
            dispatch(modalFail(e));
            closeModalCallback()
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.ERROR, `${notificationType} konnte nicht ${notificationAction} werden.`))
        });
    }
}


const copyTestGroup = (state, dispatch, values) => new Promise((resolve, reject) => {
	if (state.reviewgroup.activeProcess && values.test_group_id) {
		let urlCopy = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+values.test_group_id}/copy`;
		let urlGet = `/basedata/process/${+state.reviewgroup.activeProcess}/group/`;
		httpRequest().patch(urlCopy)
			.then(r => {
				return httpRequest().get(urlGet)
			})
			.then(r => {
				let data = {};
				for (let key in r.data) {
					data = {
						...data,
						[r.data[key].test_group_id]: r.data[key]
					}
				}
				dispatch(testGroupLoadingSuccess(data));
				resolve('ok');
			})
			.catch(e => {
				reject(e);
			})
	} else reject("process_id missing")
});

const copyTestQuestion = (state, dispatch, values) => new Promise((resolve, reject) => {
	if (state.reviewgroup.activeProcess && state.reviewgroup.activeTestGroup && values.test_question_id) {
		let urlCopy = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/${+values.test_question_id}/copy`;
		let urlGet = `/basedata/process/${+state.reviewgroup.activeProcess}/group/${+state.reviewgroup.activeTestGroup}/question/`;
		httpRequest().patch(urlCopy)
			.then(r => {
				return httpRequest().get(urlGet)
			})
			.then(r => {
				let data = {};
				for (let key in r.data) {
					data = {
						...data,
						[r.data[key].test_question_id]: r.data[key]
					}
				}
				dispatch(testQuestionLoadingSuccess(data));
				resolve('ok');
			})
			.catch(e => {
				reject(e);
			})
	} else reject("process_id or test_group missing")
});

const copyTestFault = (state, dispatch, values) => new Promise((resolve, reject) => {
	let activeProcess = state.reviewgroup.activeProcess;
	let activeGroup = state.reviewgroup.activeTestGroup;
	let activeQuestion = state.reviewgroup.activeTestQuestion;
	let faultId = values.test_fault_id;
	if (activeProcess && activeGroup && activeQuestion && faultId) {
		let urlCopy = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+faultId}/copy`;
		let urlGet = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/`;
		httpRequest().patch(urlCopy)
			.then(r => {
				return httpRequest().get(urlGet)
			})
			.then(r => {
				let data = {};
				for (let key in r.data) {
					data = {
						...data,
						[r.data[key].test_fault_id]: r.data[key]
					}
				}
				dispatch(testFaultLoadingSuccess(data));
				resolve('ok');
			})
			.catch(e => {
				reject(e);
			})
	} else reject("process_id or test_group or test_question missing")
});

const copyTestAction = (state, dispatch, values) => new Promise((resolve, reject) => {
	let activeProcess = state.reviewgroup.activeProcess;
	let activeGroup = state.reviewgroup.activeTestGroup;
	let activeQuestion = state.reviewgroup.activeTestQuestion;
	let activeFault = state.reviewgroup.activeTestFault;
    let actionId = values.test_fault_action_id;
	if (activeProcess && activeGroup && activeQuestion && activeFault && actionId) {
		let urlCopy = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/${+actionId}/copy`;
		let urlGet = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/`;
		httpRequest().patch(urlCopy, values)
			.then(r => {
				return httpRequest().get(urlGet)
			})
			.then(r => {
				let data = {};
				for (let key in r.data) {
					data = {
						...data,
						[r.data[key].test_fault_action_id]: r.data[key]
					}
				}
				dispatch(testFaultActionLoadingSuccess(data));
				resolve('ok');
			})
			.catch(e => {
				reject(e);
			})
	} else reject("process_id or test_group or test_question missing")
});

export const copyReviewGroupsHandler = (type, values) => {
	let state = store.getState();
	let func;
	if (type === GROUPS_COPY_TYPE.GROUP) func = copyTestGroup;
	else if (type === GROUPS_COPY_TYPE.QUESTION) func = copyTestQuestion;
	else if (type === GROUPS_COPY_TYPE.FAULT) func = copyTestFault;
	else if (type === GROUPS_COPY_TYPE.ACTION) func = copyTestAction;
	else func = () => new Promise((resolve) => {resolve(true)});

	return dispatch => {
        dispatch(copyStart());
		func(state, dispatch, values)
		.then(r => {
			dispatch(copySuccess())
		})
		.catch(e => {
            dispatch(copyFail(e));
            const message = axiosErrorHandler(e, `Die gewählten Daten konnten nicht kopiert werden, bitte versuchen Sie es erneut.`)
            dispatch(notificationProvider.add(NOTIFICATION_TYPES.WARN, message))
		})
	}

}

export const updateOrdering = (type, array, callback) => {
    let state = store.getState();
    let activeProcess = state.reviewgroup.activeProcess;
    let activeGroup = state.reviewgroup.activeTestGroup;
    let activeQuestion = state.reviewgroup.activeTestQuestion;
    let activeFault = state.reviewgroup.activeTestFault
    let url, func, dispAction;
    switch(type) {
        case GROUPS_COPY_TYPE.GROUP: 
                url = `/basedata/process/${+activeProcess}/group/order`;
                func = () =>loadGroups(+activeProcess);
                dispAction = updateOrderGroup;
            break;
        case GROUPS_COPY_TYPE.QUESTION: 
            url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/order`;
            func = () => loadQuestions(+activeProcess, +activeGroup);
            dispAction = updateOrderQuestion;
            break;
        case GROUPS_COPY_TYPE.FAULT: 
            url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/order`;
            func = () => loadFaults(+activeProcess, +activeGroup, +activeQuestion);
            dispAction = updateOrderFault;
            break;
        case GROUPS_COPY_TYPE.ACTION: 
            url = `/basedata/process/${+activeProcess}/group/${+activeGroup}/question/${+activeQuestion}/fault/${+activeFault}/action/order`;
            func = () => loadFaultActions(+activeProcess, +activeGroup, +activeQuestion, +activeFault);
            dispAction = updateOrderAction;
            break;
        default: return;
    }
    return dispatch => {
        httpRequest().post(url, {ordering: array})
        .then(done => {
            dispatch(func());
            dispatch(dispAction())
        })
        .catch((e) => {
            dispatch(dispAction())
        })
        
    }




}
