import React from 'react';
import './UserImageEdit.css';

/**
 * Component to handle the userpicture
 * On fileupload it crops the userpicture, previews it and forms it to a blob to upload via formdata
 * @param {Function} this.props.fileSubmitted callback when file is uploaded. provides the blob 
 * @param {Function} this.props.fileResetted callback when file is resetted.
 * @param {String} this.props.existingImage Existing userimage to display.
 */
class UserImageEdit extends React.Component {
	state = {
		showFileInput: false,
		imagePreview: null,
		newFileSubmitted: false,
		uploadErr: null
	}
	// Function to reset uploaded or provided image.
	revertInput = () => {
		if(this.props.fileResetted) this.props.fileResetted();
		this.setState({
			showFileInput: false,
			imagePreview: null,
			newFileSubmitted: false,
			uploadErr: null,
		})
	}
	// Function to set show file Input
	showFileInput = () => {
		this.setState({
			showFileInput: true,
			uploadErr: null,
		})
	}

	// method to get exact image width and height and calculated coordinates to crop
	getWidthHeight = (img, side) => {
		const {width,height } = img;
		if(width === height) {
			return [0,0,side,side];
		} else if( width < height ) {
			const ratio = height / width;
			const top = ((side * ratio) - side) /2;
			return [0,-1*top, side, side*ratio];
		} else {
			const ratio = width / height;
			const left = ((side * ratio) -side) /2;
			return [-1*left, 0, side * ratio, side];
		}
	}
	// method to crop image according to provided width to exact square 
	// returns a dataURL in base64 encoding.
	cropImage = (inputDataURI, width) => new Promise((resolve, reject) => {
		if(inputDataURI.slice(0,10)!== 'data:image') {
			reject('Die hochgeladenen Datei ist keine Bilddatei!');
		}
		const canvas = document.createElement('canvas');
		canvas.width = canvas.height = width || 300;
		const context = canvas.getContext('2d');

		let image = new Image();
		image.addEventListener('load', () => {
			context.drawImage(image, ...this.getWidthHeight(image, width));
			resolve({
				preview: canvas.toDataURL('image/jpeg', 1),
			})
		})
		image.src = inputDataURI;
	})

	// converts a base64 encoding dataURL into blob to submit via formdata
	convertCroppedIntoImage = (file) => new Promise((resolve, reject) => {
		try {
			// destructure file object and check if file is a base64 stream
			let matchString = file.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
			if(!matchString || matchString.length !== 3) {
				throw new Error('Invalid Base64 String');
			}
			// destructrue string to variables
			let type = matchString[1];
			// create new data buffer
			let data = new Buffer(matchString[2], 'base64')
			// create new blob with imageType.
			let blob = new Blob([data], { type });
			resolve(blob);
		} catch (err) {
			reject(err);
		}
	});

	// handle imageupload and cropping. 
	handleImageChange = (event) => {
		event.preventDefault();
		const reader = new FileReader();
		let file = event.target.files[0];
		reader.onloadend = () => {
			let url = reader.result;
			this.cropImage(url, 300)
			.then( img => {
				this.setState({
					imagePreview: img.preview,
					newFileSubmitted: true,
					uploadErr: null,
				});

			return this.convertCroppedIntoImage(img.preview)	
			})
			.then(image => {
				this.props.fileSubmitted(image);
			})
			.catch(e => {
				console.error(e);
				this.setState({
					showFileInput: false,
					uploadErr: e,
					imagePreview: null,
					newFileSubmitted: false,
				});
				if (this.props.fileResetted) this.props.fileResetted();
			})
		}
		if(file) {
			reader.readAsDataURL(file);
		}
	} 

	render(){
		let imagePreviewPicture = null;
		if (this.state.imagePreview && this.state.newFileSubmitted) {
			imagePreviewPicture = (
				<img
					className="user_image_edit_preview_img"
					src={this.state.imagePreview} 
					height="100" 
					width="100" 
					alt=""
				/>
			);
		} else imagePreviewPicture = (
			<img  
				className="user_image_edit_preview_img"
				src={this.props.existingImage || null } 
				height="100"
				width="100"
				alt="" 
			/>
		);

		let uploadError = null;
		if(this.state.uploadErr) uploadError = <div className="user_image_edit_add_error">{this.state.uploadErr}</div>;


		return (
			<div className="user_image_edit_container">
				<div className="user_image_edit_preview">
					{imagePreviewPicture}
					<div className="user_image_edit_overlay">
						<div className="user_image_edit_overlay_left" onClick={() => this.showFileInput()}>
							<i className="material-icons">edit</i>
						</div>
						<div className="user_image_edit_overlay_right" onClick={() => this.revertInput()}>
							<i className="material-icons">replay</i>
						</div>
					</div>
				</div>
				<div className="user_image_edit_input_container">
				{uploadError}
				{this.state.showFileInput ? (
					<React.Fragment>
						<input className="user_image_edit_input" type="file" onChange={(e) => this.handleImageChange(e)} />
						<div className="user_image_edit_add_text" >max 3MB, Unterstützt: JPEG, PNG, GIF</div>
					</React.Fragment>
				) : null}
				</div>
			</div>
		)
		
	}
}

export default UserImageEdit;