/**
 * Default.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import React from 'react';
import './Default.css';
class Default extends React.Component {
    componentDidMount() {
        document.title = "KB SOFT - Stammdaten";
    }
    
    render(){
        return(
            <div className="masterdata_default_content">
                    Bitte wählen Sie einen der oben aufgeführten Punkte zur jeweiligen Darstellung von Stammdaten.         
            </div>
        );
    }
}

export default Default;