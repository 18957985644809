import React from 'react';
import './ReviewGroups.css';

export const ReviewGroupStripe = (props) => {
    return (
        <div className={(props.additionalDragClass()) + (props.active ? " active_item ": "")}
			draggable={true}
			onDragStart={props.onDragStartFunction}
			onDragEnter={props.onDragEnterFunction}
		>
            <div className="reviewgroups_listitem_text" onClick={props.click} >
                {props.name}
            </div>

            <div className="reviewgroups_listitem_function">
				<div className="reviewgroups_listitem_function_dropdown">
					<div className="basic_btn_icon">
						<i className="material-icons">create</i>
					</div>
					<div className="reviewgroups_listitem_function_dropdown_content">
						<div className="reviewgroups_listitem_function_dropdown_content_item" onClick={props.editClick}>
							Bearbeiten
						</div>
						<div className="reviewgroups_listitem_function_dropdown_content_item" onClick={props.copyClick}>
							Kopieren
						</div>
					</div>
				</div>
            </div>
        </div>
    );
}
