/**
 * NoMatch.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */
import React from "react";
import { NavLink } from "react-router-dom";
import './../NoMatch/NoMatch.css';

import Icon from './../../assets/img/404_image.png';

class NoMatch extends React.Component {
    render() {
        return (
            <div className="nomatch_content_area">
                <div className="nomatch_content">
                    <div className="nomatch_icon">
                        <img src={Icon} alt=""/>
                    </div>
                    <div className="nomatch_text_area">
                        <div className="nomatch_text">
                            <h3>404 - Seite nicht gefunden</h3>
                            <p> Die von Ihnen angeforderte Seite konnte nicht gefunden werden. </p>
                        </div>
                        <div className="nomatch_btn_area">
                            <NavLink className="nomatch_btn" to="/"> Zurück zum Dashboard </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoMatch;
