/**
 * Dashboard.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */
import React from "react";
import {connect} from 'react-redux';
import "./Dashboard.css";

class Dashboard extends React.Component {

    componentDidMount() {
        document.title = "KB SOFT - Dashboard";
    }

    render() {
        /**-----------------------------------------------------------
         * Generate a user Greeting dependent on current time of the day
         * @author Tim.Flauger
         */
        let currentTimeOfDay = new Date().getHours();
        let greeting = "Hallo";
        if (currentTimeOfDay < 10) greeting = "Guten Morgen";
        else if (currentTimeOfDay > 10 && currentTimeOfDay < 17) greeting = "Guten Tag";
        else if (currentTimeOfDay >= 17) greeting = "Guten Abend"
        else greeting = "Hallo";
        return (
            <div className="dashboard_content_area">
                    <div className="dashboard_greeting_text">
                        {greeting},&nbsp;
                        {this.props.user_given_name}&nbsp;{this.props.user_sur_name}
                    </div>
                </div>
            );
    }
}

const mapStateToProps = state => {
    return {
        user_given_name: state.auth.user_given_name,
		user_sur_name: state.auth.user_sur_name
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
