import React from 'react';
import './Customers.css';
import {connect} from 'react-redux';
import * as actions from './../../../store/index';
// import Input from './../../../components/Input/Input';
// import Modal from './../../../components/Modal/Modal';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import ModalCustomer, {CUSTOMER_MODAL_ACTION, CUSTOMER_MODAL_TYPE} from './ModalCustomer';


const CustomerStripe = (props) => (
    <div className={"customers_navpoint " + (props.active ? "active_item": "")}>
        <div className="customers_listitem_text" onClick={props.click}>
            {props.name}
        </div>
        <div className="customers_listitem_function">
            <div className="basic_btn_icon" onClick={props.editClick}>
                <i className="material-icons">
                    create
                </i>
            </div>
        </div>
    </div>
);

const CustomerNoElements = () => (
    <div className="customers_no_elements">
        <div className="customers_listitem_text">
            Keine Kunden gefunden.
        </div>
    </div>
);

const BranchCard = (props) => (
    <div className="customers_contact_singlecard">
        <div className="customers_singlecard_top">
            <div className="customers_contact_information">
                <div className="customers_branch_name">
                    {props.name}
                </div>
                <div className="customers_information_row">
                    <div className="customers_information_icon">
                        <i className="material-icons">
                            home
                        </i>
                    </div>
                    <div  className="customers_information_text">
                       {props.street}
                    </div>
                </div>
                <div className="customers_information_row">
                    <div className="customers_information_icon">
                        <i className="material-icons">
                            my_location
                        </i>
                    </div>
                    <div  className="customers_information_text">
                        {props.zip}&nbsp;{props.city}
                    </div>
                </div>
                <div className="customers_information_row">
                    <div className="customers_information_icon">
                        <i className="material-icons">
                            map
                        </i>
                    </div>
                    <div  className="customers_information_text">
                       {props.country}
                    </div>
                </div>
            </div>
        </div>
        <div className="customers_singlecard_bottom">
            <div className="customers_singlecard_function">
                <div className="basic_btn_icon" onClick={props.editClick}>
                    <i className="material-icons">
                        edit
                    </i>
                </div>
            </div>
        </div>
    </div>
);


class Customers extends React.Component {
    state = {
        showModal: false,
        modalType: null,
        modalAction: null,
        modalContent: null,
    }

    componentDidMount() {
        if (!this.props.customerLoading && !this.props.activeCustomer) this.props.loadCustomers();
        document.title = "KB SOFT - Kunden";
    }
    
    openModal = (type, action, content) => {
        this.setState({
            showModal: true,
            modalType: type,
            modalAction: action,
            modalContent: content
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: null,
            modalAction: null,
            modalContent: null
        })
    }


    render () {
        let customerArray = [];
        let customerOutput = null;
        let branchArray = [];
        let branchOutput = null;
        let createNewBranchCard = null;
        if(this.props.customer) {
            for(let key in this.props.customer) {
                customerArray.push({
                    key: "CUSTOMER_" + key,
                    ...this.props.customer[key]
                })
            }
        }

        if(!this.props.customerLoading && customerArray.length > 0) {
            customerOutput = customerArray.map( el => (
                <CustomerStripe 
                    key={el.key}
                    id={el.customer_id}
                    name={el.customer_name}
                    active={el.customer_id === this.props.activeCustomer}
                    click={() => this.props.loadCustomerBranches(el.customer_id)}
                    editClick={() =>this.openModal(CUSTOMER_MODAL_TYPE.CUSTOMER, CUSTOMER_MODAL_ACTION.UPDATE, el)}
                />
            ))
        } else if (!this.props.customerLoading && customerArray.length === 0) {
            customerOutput = <CustomerNoElements />
        } else {
            customerOutput = <SimpleLoader />;
        }

        if(this.props.customerBranch && this.props.activeCustomer !== null) {
            for(let key in this.props.customerBranch) {
                branchArray.push({
                    key: "BRANCH_" + key,
                    ...this.props.customerBranch[key]
                })
            }
        }
        
        if (!this.props.customerBranchLoading && this.props.customerBranch && this.props.activeCustomer !== null ) {
            createNewBranchCard = (
                        <div className="customers_contact_singlecard" onClick={() =>this.openModal(CUSTOMER_MODAL_TYPE.BRANCH, CUSTOMER_MODAL_ACTION.CREATE, {})}>
                            <div className="customers_singlecard_add">
                                <div className="basic_btn_icon">
                                    <i className="material-icons">
                                        add
                                    </i>
                                </div>
                            </div>
                        </div>
                    );
        }

        if(!this.props.customerBranchLoading && branchArray.length > 0) {
            branchOutput = branchArray.map(el => (
                <BranchCard 
                    key={el.key}
                    name={el.customer_branch_name}
                    street={el.customer_branch_address}
                    zip={el.customer_branch_zip}
                    city = {el.customer_branch_city}
                    country={el.customer_branch_country}
                    editClick={() => this.openModal(CUSTOMER_MODAL_TYPE.BRANCH, CUSTOMER_MODAL_ACTION.UPDATE, el)}
                />
            ))
        } else if(this.props.customerBranchLoading) branchOutput = <SimpleLoader />; 
 



        return(
            <React.Fragment>
                <ModalCustomer 
                    show={this.state.showModal}
                    loading={this.props.modalLoading}
                    close={() => this.closeModal()}
                    action={this.state.modalAction}
                    type={this.state.modalType}
                    content={{...this.state.modalContent}}
                    modalHandler={this.props.customerModalHandler}
                    inProcessing={false}
                />
        
           <div className="customers_content_area">
               <div className="customers_content_sidebar">
                <div className="customers_list_function">
                    <div className="customers_sidebar_headline">
                        Kunden
                    </div>

                    <div className="customers_function_btn">
                        <div className="basic_btn_icon" onClick={() =>this.openModal(CUSTOMER_MODAL_TYPE.CUSTOMER, CUSTOMER_MODAL_ACTION.CREATE, {})}>
                            <i className="material-icons">
                                add
                            </i>
                        </div>
                    </div>
                </div>
                <div className="customers_list scrollvisible">
                    <div className="customers_listitems">
                        {customerOutput}
                    </div>
                </div>
               </div>
               <div className="customers_content_view">
               <div className="customers_content_subnav">
                   &nbsp;                   
                </div>
                 <div className="customers_contact_area scrollvisible">
                    <div className="customers_contact_cards">
                        {createNewBranchCard}

                    {branchOutput}
                    </div>
                </div>
               </div>
           </div>
           </React.Fragment>
        );
    }
} 

const mapStateToProps = state => {
    return {
        activeCustomer: state.customer.activeCustomer,
        customerLoading: state.customer.customerLoading,
        customer: state.customer.customer,
        customerBranch: state.customer.customerBranch,
        customerBranchLoading: state.customer.customerBranchLoading,
        modalLoading: state.customer.modalLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCustomers: () => dispatch(actions.loadCustomers()),
        loadCustomerBranches: (customer_id) => dispatch(actions.loadCustomerBranches(customer_id)),
        customerModalHandler: (type, action, values, callback) => dispatch(actions.customerModalHandler(type, action, values, callback))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
