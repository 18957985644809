/**
 * MainContent.js
 * kbsoft-webapp
 *
 * Created by Christian Jungbluth on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */
import React from "react";
import "./MainContent.css";

// import MasterData from '../container/MasterData/MasterData';
// import UserData from './../container/UserData/UserData';
import Topnav from "./../../components/Topnav/Topnav";
import NotificationCenter from "../NotificationCenter/NotificationCenter";

const MainContent = props => (
    <React.Fragment>
        <Topnav />
        <div className="main_content_area">{props.children}</div>
        <NotificationCenter />
    </React.Fragment>
);

export default MainContent;
