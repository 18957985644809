import React from 'react';
import Input from './../../components/Input/Input';
import SimpleLoader from './../../components/Loader/SimpleLoader';
import './../../components/Modal/Modal.css';
import UserImageEdit from '../../components/UserImageEdit/UserImageEdit';
import { returnUserPictureURI } from './../../utils/httpInstance';

export const USER_MODAL_TYPE = {
    USER:           "USER",
    PASSWORD:       "PASSWORD"
};

export const USER_MODAL_ACTION = {
    CREATE:         "CREATE",
    UPDATE:         "UPDATE",
    DELETE:         "DELETE"
};

export const USER_MODAL_HEADLINE = {
    USER:           "Benutzer",
    PASSWORD:       "Passwort",
    CREATE:         "erstellen",
    UPDATE:         "bearbeiten",
    DELETE:         "löschen",
}

class ModalUser extends React.PureComponent {
    state = {
        newPicture: false,
        editPicture: false,
        deletePicture: false,
        pictureData: null,
        formError: null,

        /** -----------------------------------------------------------------------------
         * User
         */
        [USER_MODAL_TYPE.USER]: {
            [USER_MODAL_ACTION.CREATE]: {
                application_title_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Anrede',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: 0, defaultOptionText: 'Bitte wählen', defaultOptionDisabled: true},  
                    value: '',  
                    touched: true, 
                    css: 'modal_content' 
                },
                user_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                user_given_name: {
                    elementType: 'inputWithLabel',
                    label: 'Vorname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                user_sur_name: {
                    elementType: 'inputWithLabel',
                    label: 'Nachname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
            [USER_MODAL_ACTION.UPDATE]: {
                user_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: true, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                },
                application_title_id: {
                    elementType: 'selectWithLabel', 
                    label: 'Anrede',        
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'text', placeholder: '', options: [], defaultValue: 0, defaultOptionText: 'Bitte wählen', defaultOptionDisabled: true},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content' 
                },
                user_mail: {
                    elementType: 'inputWithLabel',
                    label: 'E-Mail',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                user_given_name: {
                    elementType: 'inputWithLabel',
                    label: 'Vorname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                },
                user_sur_name: {
                    elementType: 'inputWithLabel',
                    label: 'Nachname',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'mail', placeholder: '', maxLength: 99},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
        },
        /** -----------------------------------------------------------------------------
         * Passwort
         */
        [USER_MODAL_TYPE.PASSWORD]: {
            [USER_MODAL_ACTION.UPDATE]: {
                user_id: {
                    elementType: 'hiddenInput',
                    label: '',              
                    validate: { required: true, regex: '', valid: false, message: '' }, 
                    elementConfig: { /* This Object needs to be empty! */ },
                    value: '', 
                    touched: true,  
                    css: '' 
                },
                password: {
                    elementType: 'inputWithLabel',
                    label: 'Passwort',
                    validate: { required: false, regex: '', valid: false, message: '' },
                    elementConfig: { type: 'password', placeholder: '', maxLength: 200 },
                    value: '',
                    touched: false,
                    css: 'modal_content'
                },
                password_repeat: {
                    elementType: 'inputWithLabel',
                    label: 'Passwort (wiederholen)',          
                    validate: { required: false, regex: '', valid: false, message: '' }, 
                    elementConfig: { type: 'password', placeholder: '', maxLength: 200},  
                    value: '',  
                    touched: false, 
                    css: 'modal_content'
                }
            },
        },
    }

    initialState = this.state;

    changeHandler = (event, targetId) => {
        let update = {
            ...this.state[this.props.type][this.props.action],
            [targetId]: {
                ...this.state[this.props.type][this.props.action][targetId],
                value: event.target.value,
                touched: true
            }
        }
        this.setState((state) =>({
            [this.props.type]: {
                ...state[this.props.type],
                [this.props.action]: update
            }
        })); 
    }

    mapValuesToState = () => {

        if(this.props.action !== null && this.props.type !== null) {
            let updateValues = {
                ...this.state[this.props.type][this.props.action]
            };
            if(this.props.type === USER_MODAL_TYPE.USER) {
                switch(this.props.action){
                        case USER_MODAL_ACTION.CREATE:
                            updateValues = {
                                ...updateValues,
                                application_title_id: {
                                    ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.CREATE].application_title_id,
                                    value: '1',
                                    elementConfig: {
                                        ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.CREATE].application_title_id.elementConfig,
                                        options: this.props.applicationTitleOptions || []
                                    }
                                },
                                user_mail: {
                                    ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.CREATE].user_mail,
                                    value: ''
                                },
                                user_given_name: {
                                    ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.CREATE].user_given_name,
                                    value: ''
                                },
                                user_sur_name: {
                                    ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.CREATE].user_sur_name,
                                    value: ''
                                }
                            }
                            break;
                        case USER_MODAL_ACTION.UPDATE:
                           updateValues = {
                               ...updateValues,
                               user_id: {
                                   ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].user_id,
                                   value: (this.props.content.user_id || '')
                               },
                               application_title_id: {
                                   ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].application_title_id,
                                   value: (this.props.content.application_title_id || "1"),
                                   elementConfig: {
                                       ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].application_title_id.elementConfig,
                                       options: this.props.applicationTitleOptions || []
                                   }
                               },
                               user_mail: {
                                   ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].user_mail,
                                   value: (this.props.content.user_mail || '')
                               },
                               user_given_name: {
                                   ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].user_given_name,
                                   value: (this.props.content.user_given_name || '')
                               },
                               user_sur_name: {
                                   ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].user_sur_name,
                                   value: (this.props.content.user_sur_name || '')
                               }
                            }
                            break;
                        default: 
                        return null;
                    }
            } else if (this.props.type === USER_MODAL_TYPE.PASSWORD) {
                updateValues = {
                        ...updateValues,
                        user_id: {
                            ...this.state[USER_MODAL_TYPE.USER][USER_MODAL_ACTION.UPDATE].user_id,
                            value: (this.props.content.user_id || '')
                        },
                }
            } else this.props.close();
            this.setState((state) =>({
                newPicture: false,
                editPicture: false,
                deletePicture: false,
                pictureData: null,
                [this.props.type]: {
                    ...state[this.props.type],
                    [this.props.action]: updateValues
                }
            }));
        }
    }

    buildSubmitObject = () => {
        let obj = {};
        for(let key in this.state[this.props.type][this.props.action]) {
            if(!this.state[this.props.type][this.props.action][key].touched) continue;
            else obj = { ...obj, [key]: this.state[this.props.type][this.props.action][key].value };
        }
        return obj;
    }

    
    
    submitHandler = () => {
		const values = this.buildSubmitObject();
		if(this.props.action === USER_MODAL_ACTION.CREATE) {
			if(Object.keys(values).length >= 3) {
				this.props.modalHandler(this.props.type, this.props.action, values, this.props.close, {
                    newPicture: this.state.newPicture, 
                    data: this.state.pictureData
                })
			} 
		} else if (this.props.type === USER_MODAL_TYPE.PASSWORD) {
            this.setState({ formError: null });
            // Check if all values are submitted -> Check if passwords match -> submit
            if(Object.keys(values).length >=3 ) {
                let passwdCompareCheck = values.password === values.password_repeat;
                let passwdLengthCheck = values.password.length >= 8;
                if (passwdCompareCheck && passwdLengthCheck) {
                    // Submit
                    this.props.modalHandler(this.props.type, this.props.action, values, this.props.close, {})
                } else {
                    // throw error to user
                    let errorOutput = "";
                    let compareErr = "Die Passwörter stimmen nicht überein.";
                    let lengthErr = "Bitte geben Sie ein Passwort mit 8 oder mehr Zeichen an.";
                    let exceptionErr = "Ein unerwarteter Fehler ist aufgetreten.\nBitte senden Sie das Formular erneut";
                    if(passwdCompareCheck && !passwdLengthCheck) errorOutput = lengthErr;
                    else if (!passwdCompareCheck && passwdLengthCheck) errorOutput = compareErr;
                    else if (!passwdCompareCheck && !passwdLengthCheck) errorOutput = lengthErr + `\n\n` + compareErr;
                    else errorOutput = exceptionErr;
                    this.setState({
                        formError: errorOutput
                    })
                }
            } 
        } else {
			if(Object.keys(values).length >= 2 || ((this.state.newPicture || this.state.editPicture || this.state.deletePicture ) && values.user_id)) {
				this.props.modalHandler(this.props.type, this.props.action, values, this.props.close, {
                    newPicture: this.state.newPicture, 
                    editPicture: this.state.editPicture, 
                    deletePicture: this.state.deletePicture,
                    data: this.state.pictureData, 
                });
			} else this.props.close();
		}
    }
    
    deleteHandler = () => {
        let delMsg = `Möchten Sie den Nutzer endgültig löschen? \nAchtung, diese Operation kann nicht Rückgängig gemacht werden!\n`;

        let delDialog = window.confirm(delMsg);
        const values = this.buildSubmitObject();
        if (delDialog) this.props.modalHandler(this.props.type, USER_MODAL_ACTION.DELETE, values, this.props.close);
        else this.props.close();
    
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.action !== this.props.action ||
        prevProps.type !== this.props.type || 
        prevProps.content !== this.props.content ) this.mapValuesToState();
        // Reset Componentstate to initialState
        if(!this.props.show && !this.props.action && !this.props.type) this.setState(this.initialState);
    }

	handleNewPicture = (blob) => {
        if (this.props.content.user_picture_file_name) {
            this.setState({
                newPicture: false,
                editPicture: true,
                deletePicture: false,
                pictureData: blob,
            })
        } else {
            this.setState({
                newPicture: true,
                editPicture: false,
                deletePicture: false,
                pictureData: blob,
            })
        }
	}

	handlePictureReset = () => {
		this.setState({
            newPicture: false,
            editPicture: false,
            deletePicture: true,
			pictureData: null
		})
	}

    render() {
        let formElements = [];
        let formContent = null;
        let formHeadline = "";
        let modal = null;
        let editPicture = null;
        let formError = null;
        if(this.props.action !== null && this.props.type !== null) {
            // No Functional Code above this If-Check!
            for(let key in this.state[this.props.type][this.props.action]) {
                formElements.push({
                    id: key,
                    config:  this.state[this.props.type][this.props.action][key]
                })
            }
            if(this.props.loading) formContent = <SimpleLoader />;
            else formContent = formElements.map(el => (
                <Input 
                    key={el.id}
                    value={el.config.value}
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    css={el.config.css}
                    label={el.config.label}
                    changed={(event) => this.changeHandler(event, el.id)}
                    disabled={this.props.inProcessing}
                />
            ))

            formHeadline = USER_MODAL_HEADLINE[this.props.type] + " " + USER_MODAL_HEADLINE[this.props.action];
		
			if (this.props.type === USER_MODAL_TYPE.USER && !this.props.loading) {
				editPicture = (
					<UserImageEdit 
						fileSubmitted={(blob) => this.handleNewPicture(blob)}
						fileResetted={this.handlePictureReset}
						existingImage = {returnUserPictureURI(!this.state.deletePicture ? this.props.content.user_picture_file_name: null)}
					/>
				);
            }
            if(this.state.formError) formError = <div className="modal_error_container"> {this.state.formError} </div>;
        } 
        // No Functional Code below this If-Check!

        if(this.props.show)
            modal = (
                <div className="modal_parent" >
                    <div className="modal">
                        <div className="modal_area">
                            <div className="modal_headline">
                                {formHeadline}
                            </div>
                            {/* {this.state[this.props.type][this.props.action].user_mail.touched ? <div className="modal_headline_warning">
                                Sie haben gerade Ihre Mailadresse geändert. <br />
                                Wenn Sie diese änderungen Speichern, werden Sie automatisch Abgemeldet.
                            </div>: null} */}
                            {editPicture}
                            
                            {formError}
                            
                            {formContent}
                            <div className="modal_functions">
								{	(this.props.type !== USER_MODAL_TYPE.PASSWORD &&
                                    (this.props.action === USER_MODAL_ACTION.UPDATE && this.props.content.user_id !== this.props.currentUserId))
                                    || this.props.content.user_role_id === 1 
									? 
										(<button className="basic_btn_icon" type="" value="Löschen" onClick={() => this.deleteHandler()}>
											<i className="material-icons">
												delete
											</i>
										</button>)
                                	:
									null
								}
                                <button className="basic_btn_icon" type="reset"  value="Abbrechen" onClick={() => this.props.close()}>
                                    <i className="material-icons">
                                        close
                                    </i>
                                </button>
                                <button className="basic_btn_icon" type="submit" value="Speichern" onClick={() => this.submitHandler()}>
                                    <i className="material-icons">
                                        save
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        return modal;
    }
}

export default ModalUser;