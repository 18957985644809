/**
 * Login.js
 * kbsoft-webapp
 *
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from './../../store/index';
import Loader from './../../components/Loader/Loader';
import './Login.css';
import Logo from './../../assets/img/login_logo.png';
import NotificationCenter from "../NotificationCenter/NotificationCenter";
class Login extends React.Component {
    state = {
        control: {
            mail: {
                type: 'email',
                placeholder: 'Mail',
                value: '',
                touched: false,
                autocomplete: "email"
            },
            password: {
                type: 'password',
                placeholder: 'Passwort',
                value: '',
                touched: false,
                autocomplete: "password"
            }
        }
    }

    componentDidMount () {
        document.title = "KB SOFT - Login "
        this.props.reAuth();
    }

    changeHandler  = (event, targetId) => {
        const updateControl = {
            ...this.state.control,
            [targetId]: {
                ...this.state.control[targetId],
                value: event.target.value,
                touched: true
            }
        }
        this.setState({control: updateControl})
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.login(this.state.control.mail.value, this.state.control.password.value);
    }



    render() {
        let authRedirect = null;
        let form = null;
        let formElements = [];
        let inputs = null;

        // eslint-disable-next-line
        for (let key in this.state.control) {
            formElements.push({
                id: key,
                config: this.state.control[key]
            });
        };

        inputs = formElements.map(el => (
            <input
                className="login_input"
                key={el.id}
                type={el.config.type}
                placeholder={el.config.placeholder}
                value={el.config.value}
                autoComplete={el.config.autoComplete}
                onChange={(event) => this.changeHandler(event, el.id)}
            />
        ));

        if(!this.props.loading) {
            form = (
                <form onSubmit={this.submitHandler}>
                    <div>
                        {inputs}
                    </div>
                    <button className="login_btn">Login</button>
                </form>
            )
        } else {
            form = <Loader />;
        }

        if (this.props.isAuth) authRedirect = <Redirect to="/" />;
        return (
            <React.Fragment>
            <div className="login_content_area">
                <div className="login_logo">
                    <img className="login_logo" src={Logo} alt=""/>
                </div>
                <div className="login_input_area">
                {authRedirect}
                {form}
                {/*
                <div className="login_passwort_missing">
                    <NavLink className="login_passwort_missing_btn" to="/forgotpassword"> Passwort vergessen</NavLink>
                </div>
                */}
                </div>
            </div>
            <NotificationCenter />
           </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuth: state.auth.accessToken !== null,
        loading: state.auth.loading,
        error: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (mail, password) => dispatch(actions.auth(mail, password)),
        reAuth: () => dispatch(actions.reAuth())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
