/**
 * customer.reducer.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

 import * as actionTypes from './../actionTypes';
import {
    _updateObject
} from './../../utils/helper';

const initialState = {
    activeCustomer: null,

    customer: {},
    customerLoading: false,
    customerError: null,

    customerBranch: {},
    customerBranchLoading: false,
    customerBranchError: null,
    
    customerContact: {},
    customerContactLoading: false,
    customerContactError: null,

    modalLoading: false,
    modalError: null,
};

const setActiveCustomer = (state, action) => {
    return _updateObject(state, {
        activeCustomer: action.activeCustomer,
        customerBranch: {},
        customerContact: {},
    });
}

//---- Customer

const customerLoadingStart = (state, action) => {
    return _updateObject(state, {
        customerLoading: true,
        customerError: action.error,
    });
}
const customerLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        customer: action.data,
        customerLoading: false,
        customerError: null,
    });
}
const customerLoadingFail = (state, action) => {
    return _updateObject(state, {
        customerLoading: false,
        customerError: action.error
    });
}

//---- CustomerBranch

const customerBranchLoadingStart = (state, action) => {
    return _updateObject(state, {
        customerBranchLoading: true,
        customerBranchError: action.error,
    });
}
const customerBranchLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        customerBranch: action.data,
        customerBranchLoading: false,
        customerBranchError: null,
    });
}
const customerBranchLoadingFail = (state, action) => {
    return _updateObject(state, {
        customerBranchLoading: false,
        customerBranchError: action.error
    });
}

//---- CustomerContact

const customerContactLoadingStart = (state, action) => {
    return _updateObject(state, {
        customerContactLoading: true,
        customerContactError: action.error,
    });
}
const customerContactLoadingSuccess = (state, action) => {
    return _updateObject(state, {
        customerContact: action.data,
        customerContactLoading: false,
        customerContactError: null,
    });
}
const customerContactLoadingFail = (state, action) => {
    return _updateObject(state, {
        customerContactLoading: false,
        customerContactError: action.error
    });
}

//---- Modal

const modalStart = (state, action ) => {
    return _updateObject(state, {
        modalLoading: true,
        modalError: false,
    })
}
const modalSuccess = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: false,
    })
}
const modalFail = (state, action ) => {
    return _updateObject(state, {
        modalLoading: false,
        modalError: action.error,
    })
}


const reviewgroupReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CUSTOMER_SET_ACTIVE_CUSTOMER:                  return setActiveCustomer(state, action);

        case actionTypes.CUSTOMER_CUSTOMER_LOADING_START:               return customerLoadingStart(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_LOADING_SUCCESS:             return customerLoadingSuccess(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_LOADING_FAIL:                return customerLoadingFail(state, action);

        case actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_START:        return customerBranchLoadingStart(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_SUCCESS:      return customerBranchLoadingSuccess(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_BRANCH_LOADING_FAIL:         return customerBranchLoadingFail(state, action);

        case actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_START:       return customerContactLoadingStart(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_SUCCESS:     return customerContactLoadingSuccess(state, action);
        case actionTypes.CUSTOMER_CUSTOMER_CONTACT_LOADING_FAIL:        return customerContactLoadingFail(state, action);

        case actionTypes.CUSTOMER_MODAL_START:                          return modalStart(state, action);
        case actionTypes.CUSTOMER_MODAL_SUCCESS:                        return modalSuccess(state, action);
        case actionTypes.CUSTOMER_MODAL_FAIL:                           return modalFail(state, action);

        default: 
            return state;
    }
}

export default reviewgroupReducer;
