/**
 * auth.reducer.js
 * kbsoft-webapp
 * 
 * Created by Tim Flauger on 20.09.2019
 * Copyright ©  digiply GmbH. All rights reserved.
 * 
 */

import * as actionTypes from './../actionTypes';
import { _updateObject } from './../../utils/helper';

const initialState = {
    accessToken: 				null,
	refreshToken: 				null,
	resourceToken: 				null,
    user_id: 					null,
    user_role_id: 				null,
    user_picture_id: 			null,
    user_mail: 					null,
    user_given_name: 			null,
    user_sur_name: 				null,
    application_title_name: 		null,
    application_title_salutation: 	null,
    user_picture_original_name: null,
    user_picture_mime_type: 	null,
    user_picture_destination: 	null,
    user_picture_file_name: 	null,
    user_picture_size: 			null,
    user_picture_encoding: 		null,
    login_destination: 			null,
    error: 						null,
    loading: 					false,
};


const authStart = (state, action) => {
    return _updateObject(state, {
        error: null, 
        loading: true
    });
}

const authSuccess = (state, action) => {
    return _updateObject(state, {
        accessToken:                action.data.access,
		refreshToken:               action.data.refresh,
		resourceToken: 				action.data.resource,
        user_id:                    action.data.user_id,
        user_role_id:               action.data.user_role_id,
        user_picture_id:            action.data.user_picture_id,
        user_mail:                  action.data.user_mail,
        user_given_name:            action.data.user_given_name,
        user_sur_name:              action.data.user_sur_name,
        application_title_name:         action.data.application_title_name,
        application_title_salutation:   action.data.application_title_salutation,
        user_picture_original_name: action.data.user_picture_original_name,
        user_picture_mime_type:     action.data.user_picture_mime_type,
        user_picture_destination:   action.data.user_picture_destination,
        user_picture_file_name:     action.data.user_picture_file_name,
        user_picture_size:          action.data.user_picture_size,
        user_picture_encoding:      action.data.user_picture_encoding,
        login_destination:          action.data.login_destination,
        error:                      null,
        loading:                    false,
    })
}

const authFail = (state, action ) => {
    return _updateObject(state, {
        ...initialState,
        error: action.error,
        loading: false
    })
}

const authLogout = (state, action) => {
    return _updateObject(state, {
        accessToken:                null,
		refreshToken:               null,
		resoureToken: 				null,
        user_id:                	null,
        user_role_id:               null,
        user_picture_id:            null,
        user_mail:              	null,
        user_given_name:            null,
        user_sur_name:              null,
        application_title_name:         null,
        application_title_salutation:   null,
        user_picture_original_name: null,
        user_picture_mime_type:     null,
        user_picture_destination:   null,
        user_picture_file_name:     null,
        user_picture_size:          null,
        user_picture_encoding:      null,
        login_destination:          null,
        error: 						null,
        loading: 					false,
        })
}

const changeUserData = (state, action) => {
    return _updateObject(state, {
        user_given_name: action.userObject.user_given_name,
        user_sur_name: action.userObject.user_sur_name,
        user_mail: action.userObject.user_mail,
        user_picture_original_name: action.userObject.user_picture_original_name,
        user_picture_mime_type: action.userObject.user_picture_mime_type,
        user_picture_destination: action.userObject.user_picture_destination,
        user_picture_file_name: action.userObject.user_picture_file_name,
        user_picture_size: action.userObject.user_picture_size,
        user_picture_encoding: action.userObject.user_picture_encoding
    })
}


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:          return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:             return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:           return authLogout(state, action);
        case actionTypes.AUTH_CHANGE_USERDATA:  return changeUserData(state, action);
        default:
            return state;
    }
};

export default authReducer;
